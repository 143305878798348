import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';
import DetalleInventario from './DetalleDevolucion';
import DetalleInventarioMod from './DetalleDevolucionMod';
import DetalleInventarioVer from './DetalleDevolucionVer';
import styled from 'styled-components';

import {
    renderField,
    AsyncSelectField,
    renderDayPickerB,
    renderNumber
} from "../../Utils/renderField/renderField";

function DevolucionCajasForm(props) {
    const {
        crear,
        bloqueo,
        modulo,
        periodoD,
        detalleInsumo,
        registroDevolucion,
        eliminarLineaInsumo,
        listarInsumos,
        listarPilotos,
        agregarLinea,
        detalleCon,
        nuevaLineaInsumo,
        modificarDevolucion,
        listarClientes,
        eliminarLinea,
        numeroDev
    } = props;

    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar devolución' : 'Nueva devolución';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver devolución'
    }
    
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                        disabled={true}
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="numero"
                                        placeholder="Número"
                                        component={renderField}
                                        className="form-control"
                                        msj="Número"
                                        disabled={true}
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="clienteN"
                                        placeholder="Cliente"
                                        component={renderField}
                                        className="form-control"
                                        msj="Cliente"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Numero"
                                            type="number"
                                            autocomplete="off"
                                            name="numero"
                                            className="form-control"
                                            value={numeroDev}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Numero
                                        </label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        msj="Cliente"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="piloto_documento"
                                        placeholder="Piloto..."
                                        className="form-control-find"
                                        loadOptions={listarPilotos}
                                        component={AsyncSelectField}
                                        msj="Piloto"
                                    />
                                </div>
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div className='row mb-2'>
                                <div className='mb-6'>
                                    <Field
                                        name="pilotoN"
                                        placeholder="Piloto"
                                        component={renderField}
                                        className="form-control"
                                        msj="Piloto"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                    />
                                </div>
                                <div className='col-3'>
                                    <div className='mb-2'>
                                        <Field
                                            name="numero"
                                            placeholder="Número"
                                            component={renderField}
                                            className="form-control"
                                            msj="Número"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="clienteN"
                                        placeholder="Cliente"
                                        component={renderField}
                                        className="form-control"
                                        msj="Cliente"
                                        disabled={true}
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        msj="Cliente"
                                    />
                                </div>
                            </div>
                        }
                        {(editar === true) &&
                        <div>
                            <div className='row mb-2'>
                                <div className='mb-6'>
                                    <Field
                                        name="pilotoN"
                                        placeholder="Piloto"
                                        component={renderField}
                                        className="form-control"
                                        msj="Piloto"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-6'>
                                    <Field
                                        name="piloto_documento"
                                        placeholder="Piloto..."
                                        className="form-control-find"
                                        loadOptions={listarPilotos}
                                        component={AsyncSelectField}
                                        msj="Piloto"
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        <div className='row mb-2'>
                            <div className='mb-2'>
                               <Field
                                    name="descripcion"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    msj="Referencia"
                                    disabled={disabled}
                                />
                            </div> 
                        </div>
                        {(crear === true || editar === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle devolución</h5>
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="insumo"
                                            placeholder="Insumo..."
                                            className="form-control-find"
                                            loadOptions={listarInsumos}
                                            component={AsyncSelectField}
                                            msj="Insumo"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            className="form-control"
                                            component={renderNumber}
                                            disabled={bloqueo}
                                            msj="Cantidad"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => agregarLinea(periodoD)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        {(editar === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => nuevaLineaInsumo(periodoD)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle devolución</h5>
                                <div style={estilo}>
                                    <DetalleInventarioVer
                                        detalle={detalleInsumo}
                                    />
                                </div>
                            </div>
                        }
                        <br/>
                        {(crear === true) &&
                            <div style={estilo}>
                                <DetalleInventario
                                    detalle={detalleCon}
                                    eliminarLinea={eliminarLinea}
                                />
                            </div>
                        }
                        {(editar === true) &&
                            <div style={estilo}>
                                <DetalleInventarioMod
                                    detalle={detalleInsumo}
                                    eliminarLinea={eliminarLineaInsumo}
                                />
                            </div>
                        }
                        <div className='d-flex flex-row mt-3'>
                            <br />
                            {crear == true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroDevolucion(periodoD, numeroDev)}
                                >
                                    Guardar
                                </button>
                            }
                            {editar == true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => modificarDevolucion(periodoD)}
                                >
                                    Modificar
                                </button>
                            }
                            <a
                                href = {`/#/produccion/${id_emp[5]}/devolucion_cajas/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DevolucionCajasForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    detalleInsumo: PropTypes.object,
    eliminarLineaProducto: PropTypes.func,
    periodoD: PropTypes.object,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormDevolucionCajasProduccion',
})(DevolucionCajasForm);
