import React, { Component } from 'react';
import Formulario from './InformesForm';
const valEmp = __CONFIG__.empresa;

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarPaises } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
            listarPaises();
        }else{
            getModulo(modulo[5],modulo[4])
            listarPaises();
        }
    }
    render(){
        const { loader, modulo, reporteListadoColaboradores, reporteBirthdays,
            listaP, excelReporteEmpleador, reporteRecibosPeriodo, listarColaborador,
            listarPlanillasRecibos, reportePromedioIngresos, listaPeriodo,
            reporteListadoFamilia, excelListadoFamilia, listarColaboradorTodos,
            reporteListadoPermisos, reporteSuspendidosIGSS, reportePrestamos,
            excelListadoColaboradores } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    reporteListadoColaboradores = {reporteListadoColaboradores}
                    reporteBirthdays = {reporteBirthdays}
                    obtenerPaises = {listaP}
                    excelReporteEmpleador = {excelReporteEmpleador}
                    listarColaborador = {listarColaborador}
                    reporteRecibosPeriodo = {reporteRecibosPeriodo}
                    listarPlanillasRecibos = {listarPlanillasRecibos}
                    reportePromedioIngresos = {reportePromedioIngresos}
                    listaPeriodo = {listaPeriodo}
                    reporteListadoFamilia = {reporteListadoFamilia}
                    excelListadoFamilia = {excelListadoFamilia}
                    listarColaboradorTodos = {listarColaboradorTodos}
                    reporteListadoPermisos = {reporteListadoPermisos}
                    reporteSuspendidosIGSS = {reporteSuspendidosIGSS}
                    reportePrestamos = {reportePrestamos}
                    excelListadoColaboradores = {excelListadoColaboradores}
                />
            </div>
        );
    }
}

export default Informes;
