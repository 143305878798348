import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Modal from '../../../components/ModalB'
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import DetalleFactura from './DetalleFactura';
import DetalleFacturaVer from './DetalleFacturaVer';

import {
    renderField,
    renderDayPickerB,
    SelectField,
    AsyncSelectField,
    renderNumber,
    renderCurrencyFloat,
    renderCurrencyFloatTC
} from "../../../Utils/renderField/renderField";

function FacturacionForm(props) {
    const {
        crear,
        bloqueo,
        editar,
        tipos,
        listarClientes,
        registroCliente,
        obtenerPaises,
        datos,
        detalleProducto,
        eliminarLinea,
        estable,
        periodoD,
        monedas,
        listarCuentas,
        detalleFac,
        agregarLinea,
        polizaFactura,
        tipoCambio,
        monedasNuevo,
        lecturaFac
    } = props;

    let autoFocus = true;

    const [total, setTotal] = useState(0.0);
    const [tipoD, setTipoD] = useState(0);
    const [editarT, setEditarT] = useState(editar);

    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [cantidad, setCantidad] = useState(0.00);

    const [tipoCambioD, setTipoCambioD] = useState(1.000000);
    const [moneda, setMoneda] = useState('');
    const [dolares, setDolares] = useState(editar === true ? lecturaFac.en_dolares : false);

    const handleChange = (e) => {
        localStorage.setItem('tipoDocumentoFacBS', e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
    }

    const handleChangeMoneda = (e) => {
        let mon = e.target.value;
        let moneda = mon.split('#');
        if (moneda[1] === 'Q') {
            setDolares(false);
            setMoneda(e.target.value);
            localStorage.setItem('monedaFacServicio', moneda[0]);
        }else{
            setDolares(true);
            setMoneda(e.target.value);
            localStorage.setItem('monedaFacServicio', moneda[0]);
        }
    }

    const tipoF = [
        { label: "LOCAL", value: "LOC" },
        { label: "EXPORTACION", value: "EXP" },
    ];

    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];
    const estilo = {
        overflow: "auto",
        height: "200px",
    };

    const tipoLinea = [
        { label: "BIENES", value: "B" },
        { label: "SERVICIOS", value: "S" },
    ];

    return (
        <div>
            {crear === false && editar == false &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <Field
                            name="codigo"
                            placeholder="Codigo establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Código establecimiento"
                        />
                    </div>
                    <div className='col-6'>
                        <Field
                            name="establecimiento"
                            placeholder="Nombre establecimiento"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Nombre establecimiento"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="correlativo"
                            placeholder="Documento interno"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Documento interno"
                        />
                    </div>
                </div>
            }
            {crear === true &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <input
                                placeholder="Codigo establecimiento"
                                type="text"
                                autocomplete="off"
                                name="codigo"
                                className="form-control"
                                value={estable.codigo}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="codigo">
                                Código establecimiento
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="form-floating">
                            <input
                                placeholder="Nombre establecimiento"
                                type="text"
                                autocomplete="off"
                                name="nombre"
                                className="form-control"
                                value={estable.nombre_comercial}
                                disabled={true}
                            />
                            <label className='form-label' htmlFor="nombre">
                                Nombre establecimiento
                            </label>
                        </div>
                    </div>
                </div>
            }
            {(crear === true) &&
                <div className='row mb-2'>
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="tipo" name="tipo"
                                value={editarT === true ? lecturaFac.tipo_documento : tipoD} onChange={handleChange} >
                                <option selected>Seleccionar...</option>
                                {tipos.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                            <label className='form-label' htmlFor="mes">Tipo de documento</label>
                        </div>
                    </div>
                    <div className='col-3'>
                        <Field
                            autoFocus={autoFocus}
                            disabled={bloqueo}
                            name="fechaRegistro"
                            component={renderDayPickerB}
                            msj="Fecha Registro"
                        />
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_factura"
                                options={tipoF}
                                crear={crear}
                                dato={crear === true ? 0 : lecturaFac.tipo_factura}
                                component={SelectField}
                                className="form-select"
                                disabled={bloqueo}
                                msj="Tipo Factura"
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <select className="form-select" id="moneda" name="moneda"
                                value={editarT === true ? lecturaFac.moneda : moneda} onChange={handleChangeMoneda} >
                                <option selected>Seleccionar...</option>
                                {monedasNuevo.map(
                                    tip => <option key={tip.value} value={tip.value}
                                    >{tip.label}
                                    </option>)
                                }
                            </select>
                                <label className='form-label' htmlFor="mes">Moneda</label>
                        </div>
                    </div>
                </div>
            }
            {crear === false && editar == false &&
            <div>
                <div className='row mb-2'>
                    <div className='col-3'>
                        {tipos &&
                            <div className="form-floating">
                                <Field
                                    name="tipo_documento"
                                    options={tipos}
                                    crear={crear}
                                    dato={crear === true ? 0 : lecturaFac.tipo_documento}
                                    component={SelectField}
                                    className="form-select"
                                    disabled={bloqueo}
                                    msj="Tipo de documento"
                                />
                            </div>
                        }
                    </div>
                    <div className='col-3'>
                        <Field
                            autoFocus={autoFocus}
                            disabled={true}
                            name="fechaRegistro"
                            component={renderDayPickerB}
                            msj="Fecha Registro"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="serie"
                            placeholder="Nombre"
                            className="form-control"
                            component={renderField}
                            disabled={true}
                            msj="Serie"
                        />
                    </div>
                    <div className='col-3'>
                        <Field
                            name="numero"
                            placeholder="Dias credito"
                            className="form-control"
                            component={renderNumber}
                            disabled={true}
                            msj="Numero"
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <Field
                            name="autorizacion"
                            disabled={true}
                            placeholder="Numero autorización"
                            className="form-control"
                            component={renderField}
                            msj="Numero autorización"
                        />
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="tipo_factura"
                                options={tipoF}
                                crear={crear}
                                dato={crear === true ? 0 : lecturaFac.tipo_factura}
                                component={SelectField}
                                className="form-select"
                                disabled={true}
                                msj="Tipo Factura"
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className="form-floating">
                            <Field
                                name="moneda"
                                options={monedas}
                                crear={crear}
                                dato={crear === true ? 0 : lecturaFac.moneda}
                                component={SelectField}
                                className="form-select"
                                disabled={true}
                                msj="Moneda"
                            />
                        </div>
                    </div>
                </div>
            </div>
            }
            {(crear === true) &&
                <div className='row mb-2'>
                    <div className='col-6'>
                        <Field
                            name="cliente_documento"
                            placeholder="Cliente..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarClientes}
                            msj="Cliente"
                        />
                    </div>
                    <div className='col-3 mt-2'>
                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(!estadoModal)}
                        >
                            Crear nuevo cliente
                        </button>
                    </div>
                </div>
            }
            {(crear === true) &&
                <div className='row mb-2'>
                    {dolares === true &&
                        <div className='col-3'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={6}
                                    fixedDecimalScale
                                    placeholder="Tipo de cambio"
                                    value={tipoCambioD}
                                    thousandSeparator
                                    onValueChange={(values) => {
                                        setTipoCambioD(values.value)
                                        localStorage.setItem('TC_facturaBienesServicio', tipoCambioD)
                                    }}
                                />
                                <label className='form-label' htmlFor="tipo">
                                    Tipo de cambio
                                </label>
                            </div>
                        </div>
                    }
                    {dolares &&
                        <div className='col-2 mt-2'>
                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => {
                                    let tc = tipoCambio(periodoD);
                                    if (tc === undefined) {
                                        setTipoCambioD('1.000000');
                                    } else {
                                        tc.then(d => {
                                            if (d === undefined) {
                                                setTipoCambioD('1.000000');
                                            } else {
                                                setTipoCambioD(d);
                                                localStorage.setItem('TC_facturaBienesServicio', d)
                                            }
                                        });
                                    }
                                }}
                            >
                                Buscar tipo de cambio
                            </button>
                        </div>
                    }
                    {polizaFactura === 'S' &&
                        <div className='col-6'>
                            <Field
                                autoFocus={true}
                                name="cuenta"
                                placeholder="Cuenta contable..."
                                className="form-control-find"
                                component={AsyncSelectField}
                                loadOptions={listarCuentas}
                                msj="Cuenta"
                            />
                        </div>
                    }
                </div>
            }
            {(crear === false && editar === false) &&
                <div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Field
                                name="clienteN"
                                disabled={true}
                                placeholder="Cliente"
                                className="form-control"
                                component={renderField}
                                msj="Cliente"
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <Field
                                name="observaciones"
                                disabled={true}
                                placeholder="Observaciones"
                                className="form-control"
                                component={renderField}
                                msj="Observaciones"
                            />
                        </div>
                    </div>
                </div>
            }
            <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
            {(crear === true || editar === true) &&
                <div>
                    <div className='mb-2'>
                        <Field
                            name="descripcion"
                            placeholder="Descripcion"
                            component={renderField}
                            className="form-control"
                            disabled={bloqueo}
                            msj="Descripción"
                        />
                    </div>
                
                    <div className='row mb-2'>
                        <div className='col-3'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_linea"
                                    options={tipoLinea}
                                    crear={crear}
                                    dato={'B'}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo"
                                />
                            </div>
                        </div>
                        <div className='col-2'>
                            <Field
                                name="cantidad"
                                placeholder="Cantidad"
                                className="form-control"
                                component={renderCurrencyFloatTC}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        setCantidad(value);
                                    }
                                }}
                                msj="Cantidad"
                            />
                        </div>
                        <div className='col-2'>
                            <Field
                                name="precio"
                                placeholder="Precio Unitario"
                                className="form-control"
                                component={renderCurrencyFloat}
                                disabled={bloqueo}
                                onChange={(e, value) => {
                                    if (value) {
                                        let total = parseFloat(parseFloat(value) * parseFloat(cantidad)).toFixed(2);
                                        setTotal(total);
                                    }
                                }}
                                msj="Precio Unitario"
                            />
                        </div>
                        <div className='col-2'>
                            <div className="form-floating">
                                <NumberFormat
                                    className={classNames('form-control')}
                                    decimalScale={2}
                                    disabled={true}
                                    placeholder="Base"
                                    fixedDecimalScale
                                    thousandSeparator
                                    value={total}
                                    name="total"
                                />
                                <label className='form-label' htmlFor="total">
                                    Total
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(crear === true) &&
                <Contenido>
                    <button
                        className='btn mr-2 mb-3 btn-primary'
                        onClick={() => {
                            agregarLinea(periodoD);
                            setTotal(0.00);
                        }}
                    >
                        Agregar linea
                    </button>
                </Contenido>
            }
            <br />
            {(crear === true) &&
                <div style={estilo}>
                    <DetalleFactura
                        detalle={detalleFac}
                        eliminarLineaProducto={eliminarLinea}
                    />
                </div>
            }
            {(crear === false && editar === false) &&
                <div style={estilo}>
                    <DetalleFacturaVer
                        detalle={detalleProducto}
                    />
                </div>
            }
            <br/>
                {(crear === false && editar === false) &&
                    <div>
                        <div className='row mb-2'>
                            <div className='col-3'>

                            </div>
                            <div className='col-2'>
                                <Field
                                    name="base"
                                    disabled={true}
                                    placeholder="Base"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    msj="Base"
                                />
                            </div>
                            <div className='col-2'>
                                <Field
                                    name="iva"
                                    disabled={true}
                                    placeholder="Iva"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    msj="Iva"
                                />
                            </div>
                            <div className='col-2'>
                                <Field
                                    name="total"
                                    disabled={true}
                                    placeholder="Total"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    msj="Total"
                                />
                            </div>
                            <div className='col-3'>
                                       
                            </div>
                        </div>
                    </div>
                }
            <br />
            <Modal
                estado={estadoModal}
                cambiarEstado={cambiarEstadoModal}
                titulo="Crear cliente"
                botonCerrar="true"
            >
                <div className="d-flex flex-column flex-1 mx-4">

                    <div className='mb-2 w-25'>
                        <Field
                            autoFocus={autoFocus}
                            name="identificador"
                            placeholder="Identificador"
                            className="form-control"
                            component={renderField}
                            msj="Identificador"
                        />
                    </div>
                    <div className='row'>
                        <div className='mb-2 col-8'>
                            <Field
                                name="nombre"
                                placeholder="Nombre"
                                className="form-control"
                                component={renderField}
                                msj="Nombre"
                            />
                        </div>
                        {obtenerPaises &&
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="pais"
                                        options={obtenerPaises}
                                        crear={crear}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Pais"
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='mb-2 w-75'>
                        <Field
                            name="direccion"
                            placeholder="Direccion"
                            className="form-control"
                            component={renderField}
                            msj="Dirección"
                        />
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <Field
                                name="telefono"
                                placeholder="Telefono"
                                className="form-control"
                                component={renderField}
                                msj="Telefono"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="correo"
                                placeholder="Correo electrónico"
                                className="form-control"
                                component={renderField}
                                msj="Correo electronico"
                            />
                        </div>
                        <div className='col-4'>
                            <Field
                                name="dias_credito"
                                placeholder="Dias credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Dias crédito"
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>
                            <Field
                                name="limite_credito"
                                placeholder="Limite credito"
                                className="form-control"
                                component={renderNumber}
                                msj="Limite crédito"
                            />
                        </div>
                        <div className='col-4'>
                            <div className="form-floating">
                                <Field
                                    name="tipo_cliente"
                                    options={tiposCL}
                                    component={SelectField}
                                    className="form-select"
                                    msj="Tipo cliente"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row mt-3'>

                        <button
                            className='btn mr-2 mb-3 btn-primary'
                            onClick={() => registroCliente(datos)}
                        >
                            Guardar
                        </button>

                        <button
                            className='btn mr-2 mb-3 btn-secondary'
                            onClick={() => cambiarEstadoModal(false)}
                        >
                            Salir
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

FacturacionForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    tipos: PropTypes.object,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    registroCliente: PropTypes.func,
    obtenerPaises: PropTypes.object,
    fechaInicio: PropTypes.string,
    fechaFinal: PropTypes.string,
    impuesto: PropTypes.object,
    datos: PropTypes.object,
    listarBodegas: PropTypes.object,
    detalleProducto: PropTypes.object,
    eliminarLineaProducto: PropTypes.func,
    leerProductos: PropTypes.func,
    estable: PropTypes.object,
    leerProductoSaldo: PropTypes.func,
    pro: PropTypes.object,
    periodoD: PropTypes.object,
    registroFacturaServicio: PropTypes.func,
    monedas: PropTypes.object,
    listarProductoServicios: PropTypes.func
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormBienesServiciosFEL',
})(FacturacionForm);
