import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import moment from 'moment';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

class FacturaEspecialList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: true
    };
    
    componentDidMount = () => {
        const { listar, leerPeriodoD, getModulo, 
            listarEstablecimientos, parametrosEmpresa } = this.props;
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5],periodo[4]);
        listar();
        listarEstablecimientos();
        parametrosEmpresa();
    }
    
    render(){
        const { data, loader, anularFacFel, imprimirFacturaEspecial,
            periodoD, modulo, listaEs, datos, anularFac } = this.props;
        let ruta = window.location.href;
        let datos_emp = ruta.split('/');
        
        return(
            <React.Fragment>
                <NavbarModulo
                    periodoD = {periodoD}
                    modulo = {modulo}
                />
                <br />
                {datos.fel === 'S' &&
                    <h3 className="p-2 bg-secondary title">Facturas especiales [ FEL ]</h3>
                }
                {datos.fel === 'N' &&
                    <h3 className="p-2 bg-secondary title">Facturas especiales</h3>
                }
                <br />
                {periodoD.estado == 'A' &&
                    <div>
                        {datos.fel === 'N' &&
                            <div className="d-flex flex-row justify-content-start mb-2">
                            </div>
                        }
                        {datos.fel === 'S' &&
                            <div className="d-flex flex-row justify-content-start mb-2">
                                <a
                                    href={`/#/compras/${datos_emp[5]}/factura_especial_fel/${datos_emp[7]}/crear/${listaEs.id}/`}
                                    className='btn btn-primary'
                                >
                                    Crear Factura
                                </a>
                                
                            </div>
                        }
                    </div>
                }
                
                {datos.fel === 'S' && periodoD.estado == 'A' && datos.agricola === 'S' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_registro"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombrePro"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Proveedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_fac_fel_especial: "factura_especial_fel",
                                    editar_fac_fel: "factura_especial_fel",
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac_fel: anularFacFel,
                                    certificadorFel: datos.certificador
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && periodoD.estado == 'C' && datos.agricola === 'S' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_registro"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombrePro"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Proveedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_fac_fel_especial: "factura_especial_fel",
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>
                }
                {datos.fel === 'S' && periodoD.estado == 'A' && datos.agricola === 'N' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_registro"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombrePro"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Proveedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_fac_fel_especial: "factura_especial_fel",
                                    editar_fac_fel: "factura_especial_fel",
                                    imprimirFactura: imprimirFacturaEspecial,
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                width="100"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({
                                    anularFac_fel: anularFacFel,
                                    certificadorFel: datos.certificador
                                })} 
                            >
                                Anular
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>        
                }
                {datos.fel === 'S' && periodoD.estado == 'C' && datos.agricola === 'N' &&
                    <div>
                    {data &&
                        <Grid 
                            hover 
                            striped 
                            data={data} 
                            loading={loader} 
                            headerStyle={ { background: 'black' } } 
                            //onPageChange={onPageChange}
                            //onSortChange={onSortChange} 
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="codigo"
                                headerAlign="center"
                                dataAlign='right'
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_registro"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    return moment(cell).format("DD/MM/YYYY");
                                }}
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="serie"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Serie
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Numero
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="400"
                                dataField="nombrePro"
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Proveedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                headerAlign="center"
                                dataAlign='right'
                                dataField="total"
                                dataFormat={(cell, row)=>{
                                    return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                }}
                                dataSort
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataSort
                                width="200"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Descripcion
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="estatus"
                                dataAlign='center'
                                dataSort
                                width="140"
                                thStyle={
                                    {color: 'white'}}
                                tdStyle={
                                    {color: 'white'}}
                            >
                                Estado
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                thStyle={
                                {color: 'white'}}
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={standardActions({ 
                                    ver_fac_fel_especial: "factura_especial_fel",
                                    imprimirFactura: imprimirFacturaEspecial,
                                    empresa: datos_emp[5],
                                    periodo: datos_emp[7],
                                    modulo: datos_emp[4],
                                })} 
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Grid>
                    }
                </div>        
                }
                {datos.fel === 'N' && periodoD.estado == 'A' &&
                    <div>
                        {data &&
                            <Grid 
                                hover 
                                striped 
                                data={data} 
                                loading={loader} 
                                headerStyle={ { background: 'black' } } 
                                //onPageChange={onPageChange}
                                //onSortChange={onSortChange} 
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField="codigo"
                                    headerAlign="center"
                                    dataAlign='right'
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Codigo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataSort
                                    dataFormat={(cell, row)=>{
                                        return moment(cell).format("DD/MM/YYYY");
                                    }}
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="serie"
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Serie
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="numero"
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Numero
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="400"
                                    dataField="nombreCli"
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Cliente
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerAlign="center"
                                    dataAlign='right'
                                    dataField="total"
                                    dataFormat={(cell, row)=>{
                                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                    }}
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Total
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="descripcion"
                                    dataSort
                                    width="200"
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Descripcion
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="estatus"
                                    dataAlign='center'
                                    dataSort
                                    width="140"
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Estado
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        editar_inv: "facturas", 
                                        ver_inv: "facturas",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    width="100"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({
                                        anularFac: anularFac
                                    })} 
                                >
                                    Anular
                                </TableHeaderColumn>
                            </Grid>
                        }
                    </div>
                }
                {datos.fel === 'N' && periodoD.estado == 'C' &&
                    <div>
                        {data &&
                            <Grid 
                                hover 
                                striped 
                                data={data} 
                                loading={loader} 
                                headerStyle={ { background: 'black' } } 
                                //onPageChange={onPageChange}
                                //onSortChange={onSortChange} 
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField="codigo"
                                    headerAlign="center"
                                    dataAlign='right'
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Codigo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataSort
                                    dataFormat={(cell, row)=>{
                                        return moment(cell).format("DD/MM/YYYY");
                                    }}
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="serie"
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Serie
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="numero"
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Numero
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="400"
                                    dataField="nombreCli"
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Cliente
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    headerAlign="center"
                                    dataAlign='right'
                                    dataField="total"
                                    dataFormat={(cell, row)=>{
                                        return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Math.abs(cell));
                                    }}
                                    dataSort
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Total
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="descripcion"
                                    dataSort
                                    width="200"
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Descripcion
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="estatus"
                                    dataAlign='center'
                                    dataSort
                                    width="140"
                                    thStyle={
                                        {color: 'white'}}
                                    tdStyle={
                                        {color: 'white'}}
                                >
                                    Estado
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    thStyle={
                                    {color: 'white'}}
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({ 
                                        ver_inv: "facturas",
                                        empresa: datos_emp[5],
                                        periodo: datos_emp[7],
                                        modulo: datos_emp[4],
                                    })} 
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </Grid>
                        }
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default FacturaEspecialList;
