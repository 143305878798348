import React, { Component } from 'react';
import Formulario from './FacturaServiciosForm';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

class FacturaServicios extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leerS, getModulo,leerPeriodoD, vaciarServicios, listarPaises,
            listarTiposDocu, parametrosEmpresa, leerEstablecimiento, listarMonedas } = this.props;
        const id = match.params.id;
        const id_est = match.params.estable;
        if (id){
            this.setState({crear: false});
            leerS(id);
        }
        if (id === undefined){
            leerEstablecimiento(id_est);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        listarTiposDocu();
        vaciarServicios();
        listarPaises();
        listarMonedas();
    }
    
    render(){
        const { loader, modulo, periodoD, datos, monedas,
            detalleProducto, detalleCon, eliminarLineaServ, listaP,
            estable, tipos, agregarLineaServ, tipoCambioServ,
            listarClientes, registroFacturaServicio, lecturaFac } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    datos = {datos}
                    detalle = {detalleProducto}
                    detalleCon = {detalleCon}
                    eliminarLineaProducto = {eliminarLineaServ}
                    registroFacturaServicio = {registroFacturaServicio}
                    estable = {estable}
                    tipos = {tipos}
                    obtenerPaises = {listaP}
                    agregarLinea = {agregarLineaServ}
                    lecturaFac = {lecturaFac}
                    listarClientes = {listarClientes}
                    tipoCambio = {tipoCambioServ}
                    monedas = {monedas}
                />
            </LoadMask>
        );
    }
}

export default FacturaServicios
