import React, { Component } from 'react';
import Formulario from './InformesForm';
const valEmp = __CONFIG__.empresa;

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo, listarPaises } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        if (valEmp === 'fage'){
            getModulo(modulo[6],modulo[5])
            listarPaises();
        }else{
            getModulo(modulo[5],modulo[4])
            listarPaises();
        }
    }
    render(){
        const { loader, modulo, listaPeriodo, listarPlanillas,
            listaP, reportePlanilla, reportePlanillaMensual, listarColaborador,
            reporteCuotasIgss, excelCuotasIgss, reporteRecibosPeriodo,
            listarPlanillasRecibos, reportePromedioIngresos } = this.props;
        return(
            <div>
                <Formulario
                    loader = {loader}
                    modulo = {modulo}
                    obtenerPaises = {listaP}
                    listaPeriodo = {listaPeriodo}
                    listarPlanillas = {listarPlanillas}
                    reportePlanilla = {reportePlanilla}
                    reportePlanillaMensual = {reportePlanillaMensual}
                    reporteCuotasIgss = {reporteCuotasIgss}
                    excelCuotasIgss = {excelCuotasIgss}
                    reporteRecibosPeriodo = {reporteRecibosPeriodo}
                    listarColaborador = {listarColaborador}
                    listarPlanillasRecibos = {listarPlanillasRecibos}
                    reportePromedioIngresos = {reportePromedioIngresos}
                />
            </div>
        );
    }
}

export default Informes;
