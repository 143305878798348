import React, { Component } from 'react';
import Formulario from './FacturaBienesForm';
import LoadMask from "../../../Utils/LoadMask/LoadMask";

class FacturaBienes extends Component{
    state = {
        crear: true,
    }

    componentDidMount = () => {
        const { match, leerB, getModulo,leerPeriodoD, vaciarBienes, listarPaises,
            listarTiposDocu, parametrosEmpresa, leerEstablecimiento, listarMonedas } = this.props;
        const id = match.params.id;
        const id_est = match.params.estable;
        if (id){
            this.setState({crear: false});
            leerB(id);
        }
        if (id === undefined){
            leerEstablecimiento(id_est);
        }
        let ruta = window.location.href;
        let periodo = ruta.split('/');
        leerPeriodoD(periodo[5], periodo[7]);
        getModulo(periodo[5], periodo[4]);
        parametrosEmpresa();
        listarTiposDocu();
        vaciarBienes();
        listarPaises();
        listarMonedas();
    }
    
    render(){
        const { loader, modulo, periodoD, datos, listarBodegas, pro, registroConsumo,
            listarProductos, detalleProducto, detalleCon, eliminarLinea, listaP,
            leerProductos, estable, tipos, agregarLinea, modificarConsumo, tipoCambioB,
            listarClientes, registroFacturaBienes, lecturaFac, monedas, registroCliente } = this.props;
        const { crear } = this.state;
        return(
            <LoadMask loading={loader} dark>
                <Formulario
                    crear = {crear}
                    modulo = {modulo}
                    periodoD = {periodoD}
                    datos = {datos}
                    listarProductos = {listarProductos}
                    detalleCon = {detalleCon}
                    eliminarLineaProducto = {eliminarLinea}
                    registroConsumo = {registroConsumo}
                    modificarConsumo = {modificarConsumo}
                    listarBodegas = {listarBodegas}
                    leerProductos = {leerProductos}
                    detalleProducto = {detalleProducto}
                    pro = {pro}
                    registroFacturaBienes = {registroFacturaBienes}
                    estable = {estable}
                    tipos = {tipos}
                    obtenerPaises = {listaP}
                    agregarLinea = {agregarLinea}
                    lecturaFac = {lecturaFac}
                    listarClientes = {listarClientes}
                    tipoCambio = {tipoCambioB}
                    monedas = {monedas}
                    registroCliente = {registroCliente}
                />
            </LoadMask>
        );
    }
}

export default FacturaBienes
