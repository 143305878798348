import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_FACTURA = 'LISTADO_FACTURA';
const LISTADO_FACTURA_SALDOS = 'LISTADO_FACTURA_SALDOS';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const LISTADO_CLIENTE = 'LISTADO_CLIENTE';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const PERIODO = 'PERIODO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODOD';
const PARAMETRO = 'PARAMETRO';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const DETALLE_PRODUCTOS_SERVICIO = 'DETALLE_PRODUCTOS_SERVICIO';
const PRODUCTOS = 'PRODUCTOS';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const LISTADO_MONEDAS_NUEVO = 'LISTADO_MONEDAS_NUEVO';
const DETALLE_FACTURA = 'DETALLE_FACTURA';
const DETALLE = 'DETALLE';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    //const params = { page, id, id_period };
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/factura', params).then((response) => {
        dispatch({ type: LISTADO_FACTURA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar facturas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tiposFac', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const listarNiveles = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaFactura');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FacturacionFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const registroFactura = (periodoD, impuesto) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturas.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    if (fecha>=fechaI && fecha<=fechaF){
        let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
        
        let iva = JSON.parse(localStorage.getItem('A_ivaF'));
        let bienes = data.bienes;
        let servicios = data.servicios;
        let exento = data.exento;
        
        if (bienes === undefined){
            bienes = 0.00
        }
        if (servicios === undefined){
            servicios = 0.00
        }
        if (exento === undefined){
            exento = 0.00
        }
        
        let baseB = 0.00;
        let ivaB = 0.00;
        let SumBaD = 0.00;
        let baseS = 0.00;
        let ivaS = 0.00;
        let SumIvD = 0.00;
        let SumTotalD = 0.00;

        if (iva === true){
            let tipo = localStorage.getItem('tipo_factura_venta');
            if (tipo === 'EXP'){
                SumBaD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
                SumIvD = 0.00;
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            }else{
                baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);
                
                baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);
            
                SumBaD = parseFloat(parseFloat(baseB)+parseFloat(baseS)).toFixed(2);

                SumIvD = parseFloat(parseFloat(ivaB)+parseFloat(ivaS)).toFixed(2);
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            }
        }
        if (iva === false){
            SumBaD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            SumIvD = 0.00;
            SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
        }
        
        const formData = {
            fechaRegistro: data.fechaRegistro,
            serie: data.serie.toUpperCase(),
            numero: data.numero,
            subTotal: data.subtotal,
            tipoDoc: localStorage.getItem('tipoDocumentoFac'),
            tipo: data.tipo,
            anulado: data.anulado === false || data.anulado === undefined ? '**ACTIVO**' : '**ANULADO**',
            periodo: id_emp[7],
            cliente: data.anulado === true ? null :  data.cliente_documento.value,
            descripcion: data.descripcion,
            establecimiento: id_emp[9],
            bienes: data.bienes,
            servicios: data.servicios,
            exento: data.exento,
            iva: SumIvD,
            base: SumBaD,
            total: SumTotalD,
            modulo: 'FAC',
            tipo_cambio: localStorage.getItem('TC_facturaVent'),
            en_dolares: data.en_dolares ? data.en_dolares : localStorage.getItem('dolares_fact'),
            tipo_factura: localStorage.getItem('tipo_factura_venta'),
            empresa: id_emp[5],
            cuenta: data.cuenta ? data.cuenta.value : '',
        }
        
        dispatch(setLoader(true));
        api.post('/factura/', formData).then((response) => {
            NotificationManager.success(
                'Factura creada correctamente',
                'Exito',
                3000
            );
            if (response.idPoliza){
                localStorage.setItem('conf_FacturaVenta', 'T');
                localStorage.setItem('idPolizaFactura', response.idPoliza);
                dispatch(leerDetalle(response.idPoliza));
            }else{
                setTimeout(function(){
                    window.location.reload(true);
                }, 2000);
            }
        }).catch((error) => {
            localStorage.setItem('conf_FacturaVenta', 'F');
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        localStorage.setItem('conf_FacturaVenta', 'F');
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const modificarFactura = (periodoD, impuesto) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturas.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    const poliza = localStorage.getItem('idPolizaFactura');
    if (fecha>=fechaI && fecha<=fechaF){
        const id = data.id;
        let PIva = parseFloat(parseFloat(parseFloat(impuesto.porcentaje_iva)/100)+1).toFixed(2);
        
        let iva = data.afectaIva === 'IVA' ? true : false;
        let bienes = data.bienes;
        let servicios = data.servicios;
        let exento = data.exento;
        
        if (bienes === undefined){
            bienes = 0.00
        }
        if (servicios === undefined){
            servicios = 0.00
        }
        if (exento === undefined){
            exento = 0.00
        }
        
        let baseB = 0.00;
        let ivaB = 0.00;
        let SumBaD = 0.00;
        let baseS = 0.00;
        let ivaS = 0.00;
        let SumIvD = 0.00;
        let SumTotalD = 0.00;
        
        if (iva === true){
            let tipo = localStorage.getItem('tipo_factura_venta') ? localStorage.getItem('tipo_factura_venta') : data.tipo_factura;
            if (tipo === 'EXP'){
                SumBaD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
                SumIvD = 0.00;
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            }else{
                baseB = parseFloat(parseFloat(bienes) / PIva).toFixed(2);
                ivaB = parseFloat(parseFloat(bienes) - parseFloat(baseB)).toFixed(2);
                
                baseS = parseFloat(parseFloat(servicios) / PIva).toFixed(2);
                ivaS = parseFloat(parseFloat(servicios) - parseFloat(baseS)).toFixed(2);
            
                SumBaD = parseFloat(parseFloat(baseB)+parseFloat(baseS)).toFixed(2);
    
                SumIvD = parseFloat(parseFloat(ivaB)+parseFloat(ivaS)).toFixed(2);
                SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            }
        }
        if (iva === false){
            SumBaD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
            SumIvD = 0.00;
            SumTotalD = parseFloat(parseFloat(bienes)+parseFloat(servicios)+parseFloat(exento)).toFixed(2);
        }

        let cliente = data.cliente_documento;
        if (cliente === undefined){
            cliente = data.cliente;
        }else{
            cliente = data.cliente_documento.value;
        }
        
        let tipoDocumento = data.tipo_doc;
        if (tipoDocumento === undefined){
            tipoDocumento = data.tipo_documento;
        }else{
            tipoDocumento = data.tipo_doc;
        }
        
        let tipoFactura = data.tipo_fac;
        if (tipoFactura === undefined){
            tipoFactura = data.tipo_factura;
        }else{
            tipoFactura = data.tipo_fac;
        }

        const formData = {
            id: id,
            fechaRegistro: data.fechaRegistro,
            anulado: data.anulado === false || data.anulado === undefined ? '**ACTIVO**' : '**ANULADO**',
            serie: data.serie.toUpperCase(),
            numero: data.numero.toUpperCase(),
            subTotal: data.subtotal,
            tipoDoc: tipoDocumento,
            tipo: data.tipo,
            cliente: data.anulado === true ? null : cliente,
            descripcion: data.descripcion,
            bienes: data.bienes,
            servicios: data.servicios,
            exento: data.exento,
            iva: SumIvD,
            base: SumBaD,
            total: SumTotalD,
            modulo: 'FAC',
            en_dolares: tipoFactura === 'EXP' ? 'True' : data.en_dolares,
            tipo_cambio: data.tipo_cambio,
            tipo_factura: tipoFactura,
            poliza: 'ventas',
            empresa: id_emp[5],
        }
        dispatch(setLoader(true));
        api.put(`/factura/${id}/`, formData).then(() => {

            NotificationManager.success(
                'Factura modificada correctamente',
                'Exito',
                3000
            );

            if (poliza){
                localStorage.setItem('conf_FacturaVenta', 'T');
                localStorage.setItem('idPolizaFactura', poliza);
                dispatch(leerDetalle(poliza));
            }else{
                let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`
                dispatch(push(ruta));
            }
            
        }).catch((error) => {
            localStorage.setItem('conf_FacturaVenta', 'F');
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        localStorage.setItem('conf_FacturaVenta', 'F');
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { id };
    api.get("/factura/factura_s_fel/", params).then((response) => {
        response.fechaRegistro = response.fecha_registro;
        response.identificador = response.nit_proveedor;
        response.cliente = response.cliente;
        if (response.estatus === '**ANULADO**'){
            response.anulado = true;
        }
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormFacturas', response));
        if (response.poliza){
            localStorage.setItem('idPolizaFactura', response.poliza);
            dispatch(leerDetalleMod(response.poliza));
        }else{
            response.polizaDetalle_polizaEncabezado = [];
            dispatch({ type: GUARDAR_POLIZA, lectura: response });
        }
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leerInvBienes = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        if (response.tipo === 'B'){
            response.fechaRegistro = response.fecha_registro;
            response.identificador = response.nit_proveedor;
            response.cliente = response.cliente;
            response.nombreCli = response.nombreCli;
            const editar = window.location.href.includes('editar');
            if (editar){
                localStorage.setItem('idPolizaFactura', response.poliza);
            }
            if (response.estatus === '**ANULADO**'){
                response.anulado = true;
            }
            localStorage.setItem('id_detalle_factura_salida', response.id);
            dispatch({ type: GUARDAR_FACTURA, lectura: response });
            dispatch(initializeForm('FormInventarioFactura', response));
            dispatch(leerDetalleProductos(response.id, response.empresa));
        }
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}


export const registroPolizaDetalleSCCServicio = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FacturacionServicioFormTabs.values;
    const poliza = localStorage.getItem('id_poliza_servicio_factura');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('id_poliza_servicio_factura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionServicioFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('id_poliza_servicio_factura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionServicioFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleCCServicio = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FacturacionServicioFormTabs.values;
    const poliza = localStorage.getItem('id_poliza_servicio_factura');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('id_poliza_servicio_factura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionServicioFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('id_poliza_servicio_factura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionServicioFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionServicioFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FacturacionFormTabs.values;
    const poliza = localStorage.getItem('idPolizaFactura');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaFactura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaFactura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FacturacionFormTabs.values;
    const poliza = localStorage.getItem('idPolizaFactura');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaFactura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaFactura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idPolizaFactura');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

const eliminarFacturaSaldo = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`factura/${id}`).then(() => {
        dispatch(listarFacSaldos());
        NotificationManager.success(
            'Factura borrada correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            error.mssj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarFacturaSFEL = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`factura/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(
            'Factura borrada correctamente',
            'Exito',
            3000
        );
    }).catch((error) => {
        let tableRows = '';
        error.msj.forEach(item => {
            tableRows += `<tr><td>${item.formato_fecha}</td><td>${item.serie}</td><td>${item.numero}</td><td>${item.tipo}</td></tr>`;
        })
        Swal.fire({
            title: 'Abonos aplicados',
            html: `
                <table>
                    <thead>
                        <tr>
                            <th style="width: 100px">Fecha</th>
                            <th style="width: 100px">Serie</th>
                            <th style="width: 100px">Número</th>
                            <th style="width: 100px">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tableRows}
                    </tbody>
                </table>
            `,
            icon: 'warning',
            showCloseButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar',
        }).then((result) => {
            if (result.isConfirmed) {
                
            }
        });
    }).finally(() => {
        dispatch(setLoader(false));
    });
};



export const registroCliente = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturas.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S' && data.tipo_cliente === 'L'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Factura',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        if (datos.validar_dpi === 'S' && data.tipo_cliente === 'P'){
            let valid = validar.valCui(data.identificador);
            if (valid[0].validar === false){
                Swal.fire({
                    title: 'Factura',
                    text: 'DPI con formato inválido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccionCli,
                    telefono: data.telefonoCli,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
        if (datos.validar_nit === 'S' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    })
}

export const registroFacturaSaldo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaSaldo.values;
    const formData = {
        fechaRegistro: data.fechaRegistro,
        serie: data.serie.toUpperCase(),
        numero: data.numero.toUpperCase(),
        cliente: data.anulado === true ? null :  data.cliente_documento.value,
        descripcion: data.descripcion,
        establecimiento: id_emp[8],
        total: data.total,
        tipo_cambio: data.tipo_cambio,
        en_dolares: data.en_dolares,
        modulo: 'FAC',
        tipo_factura: 'SAL',
        empresa: id_emp[5],
        tipoFactura: 'saldo'

    }
    
    dispatch(setLoader(true));
    api.post('/factura/', formData).then((response) => {
        dispatch(initializeForm('FormFacturaSaldo', response));
        const editar = window.location.href.includes('editar');
        NotificationManager.success(
            'Factura creada correctamente',
            'Exito',
            3000
        );
        if (editar){
            let ruta = `/facturacion/${id_emp[5]}/saldos`
            dispatch(push(ruta));
        }
        
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarFacSaldos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let saldo = 'saldo';
    //const params = { page, id, saldo };
    const params = { id, saldo };
    dispatch(setLoader(true));
    api.get('/factura', params).then((response) => {
        dispatch({ type: LISTADO_FACTURA_SALDOS, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar facturas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerSaldo = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha_registro;
        response.identificador = response.nit_proveedor;
        response.cliente = response.cliente;
        dispatch({ type: GUARDAR_FACTURA, lectura: response });
        dispatch(initializeForm('FormFacturaSaldo', response));
        
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const modificarFacturaSaldo = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaSaldo.values;
    const id = data.id;
    let cliente = data.cliente_documento;
    if (cliente === undefined){
        cliente = data.cliente;
    }else{
        cliente = data.cliente_documento.value;
    }
    
    const formData = {
        id: id,
        fechaRegistro: data.fechaRegistro,
        serie: data.serie.toUpperCase(),
        numero: data.numero.toUpperCase(),
        cliente: data.anulado === true ? null :  cliente,
        total: data.total,
        descripcion: data.descripcion,
        tipo_cambio: data.tipo_cambio,
        en_dolares: data.en_dolares,
        tipoFactura: 'saldo',
        tipo_factura: 'SAL',
    }
    dispatch(setLoader(true));
    api.put(`/factura/${id}/`, formData).then(() => {
        let ruta = `/facturacion/${id_emp[5]}/saldos`
        dispatch(push(ruta));
        NotificationManager.success(
            'Factura modificada correctamente',
            'Exito',
            3000
        );
        
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar factura',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        const estable = [];
        data.results.forEach(item=>{
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1 ){
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        }else{
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
        
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const registroClienteSaldo = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaSaldo.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S' && data.tipo_cliente === 'L'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        if (datos.validar_dpi === 'S' && data.tipo_cliente === 'P'){
            let valid = validar.valCui(data.identificador);
            if (valid[0].validar === false){
                Swal.fire({
                    title: 'Factura',
                    text: 'DPI con formato inválido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccionCli,
                    telefono: data.telefonoCli,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
        if (datos.validar_nit === 'S' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}


export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const parametrosEmpresaValidar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_impuesto', params).then((imp) => {
            if (imp.validar === 'false'){
                Swal.fire({
                    title: 'Factura',
                    text: imp.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/ajustes/${id_emp[5]}/impuestos`
                        dispatch(push(ruta));
                    }
                });
            }
        }).catch((error)=>{
            
            NotificationManager.error(
                'Ocurrió un error al consultar parametros',
                'Error',
                8000
            );
        })
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarImpuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    dispatch(setLoader(true));
    api.get('/impuesto/impuesto_empresa', params).then((response) => {
        dispatch({ type: LISTADO_IMPUESTO, impuesto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar impuesto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({type: ESTABLECIMIENTO, estable: response });
        
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod_inventario',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                list_producto.push({
                    value: item.id+' # '+item.bodega+' # '+item.saldo,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Bodega: ' +item.numeroBodega + ' Existencia: ' + item.saldo
                })
            })
        }
        return list_producto;
    }).catch((error)=>{
        return [];
    })
}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

const leerDetalleProductos = ( id, empresa ) => (dispatch) => {
    const params = { id, empresa };
    
    api.get('/movimiento_inventario/movimiento_inventario_factura', params).then((response)=>{
        if (response.idMov){
            localStorage.setItem('id_detalle_inventario_factura', response.idMov);
        }
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response.data });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleProductosInv = id => (dispatch) => {
    api.get(`/movimiento_inventario_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const eliminarLineaProducto = id => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let empresa = id_emp[5];
    const params = {
        id: id, 
        empresa: empresa
    }
    dispatch(setLoader(true));
    api.post('factura/linea_factura_inventario_delete', params).then((response) => {
        dispatch(leerDetalleProductos(response.id, id_emp[5]));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const eliminarLineaProductoServicio = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/factura_detalle/${id}`).then((response) => {
        dispatch(leerDetalleFacturaServicio(response.id));
        if (response.poliza){
            dispatch(leerDetalleMod(response.poliza));
        }
        NotificationManager.success(
            'Servicio eliminado correctamente',
            'Exito',
            3000
        );
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let form = 'F'
    const params = {id, idEmpre, form }
    api.get('/producto/empresa_productos_cod_inventario_bodega/',params).then((response) => {
        const producto = [];
        response.forEach(item=>{
            if (item.tipoPro === 'S' || item.tipoPro === 'C'){
                producto.push({
                    value: item.id+' # '+ item.saldo + '#' + item.tipoPro,
                    label: item.sku + ' / ' + item.productoN
                })
            }
            if (item.tipoPro === 'P'){
                producto.push({
                    value: item.id+' # '+ item.saldo + '#' + item.tipoPro,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + item.saldo
                })
            }
            
        })
        dispatch({type: PRODUCTOS, pro: producto })
    })
}

export const registroProductoInventario = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormInventarioFactura.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let cliente = data.cliente_documento;
    if (cliente === undefined){
        cliente = data.cliente;
    }else{
        cliente = data.cliente_documento.value;
    }
    if (fecha>=fechaI && fecha<=fechaF){
        const detalle = localStorage.getItem('id_detalle_inventario_factura');
        const detalleF = localStorage.getItem('id_detalle_factura_salida');
        let datos_p = data.producto.value;
        let datos_pro = datos_p.split('#');
        let exis = parseFloat(datos_pro[1].trim())
        
        if (datos.movimiento_s_existencia === 'N')
            if (parseFloat(data.cantidad)<=exis && datos_pro[2].trim() === 'P'){
                const formData = {
                    fechaRegistro: data.fechaRegistro,
                    serie: data.serie.toUpperCase(),
                    numero: data.numero,
                    moneda: data.moneda,
                    tipo: 'B',
                    cliente: cliente,
                    descripcion: data.descripcion,
                    tipoDoc: localStorage.getItem('tipoDocumentoFacIn'),
                    tipo_factura: data.tipo_factura ? data.tipo_factura : 'LOC',
                    establecimiento: id_emp[9],
                    modulo: 'FAC',
                    bodega: data.bodega.value,
                    producto: datos_pro[0].trim(),
                    cantidad: data.cantidad,
                    precio_unitario: data.precio,
                    total: parseFloat(localStorage.getItem('total_linea_factura')).toFixed(2),
                    id_compra: '',
                    costo_unitario_s_iva: 0.00,
                    total_s_iva: 0.00,
                    motivo: 'FACT',
                    descripcion: data.descripcion,
                    empresa: id_emp[5],
                    periodo: id_emp[7],
                    detalle: detalle,
                    detalleF: detalleF
                }
                
                dispatch(setLoader(true));
                api.post('/factura/factura_inventario/', formData).then((response) => {
                    if (detalle){
                        response.serie = response.encabezado.serie;
                        response.numero = response.encabezado.numero;
                        response.fechaRegistro = response.encabezado.fecha_registro;
                        response.descripcion = response.encabezado.descripcion;
                        response.nombreCli = response.encabezado.nombreCli;
                        response.codigo = response.encabezado.codigo;
                        response.establecimiento = response.encabezado.establecimiento;
                        response.id = response.encabezado.id;
                        response.moneda = response.encabezado.moneda;
                        response.cliente = response.encabezado.cliente;
                        dispatch(initializeForm('FormInventarioFactura', response));
                        dispatch(leerDetalleProductosInv(response.detalle));
                    }else{
                        response.serie = response.encabezado.serie;
                        response.numero = response.encabezado.numero;
                        response.fechaRegistro = response.encabezado.fecha_registro;
                        response.descripcion = response.encabezado.descripcion;
                        response.codigo = response.encabezado.codigo;
                        response.establecimiento = response.encabezado.establecimiento;
                        response.id = response.encabezado.id;
                        response.moneda = response.encabezado.moneda;
                        response.cliente = response.encabezado.cliente;
                        localStorage.setItem('id_detalle_inventario_factura', response.detalle.encabezado);
                        localStorage.setItem('id_detalle_factura_salida', response.encabezado.id);
                        dispatch(initializeForm('FormInventarioFactura', response));
                        dispatch(leerDetalleProductosInv(response.detalle.encabezado));
                    }
                    NotificationManager.success(
                        'Producto ingresado correctamente',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }else {
                if (datos_pro[2].trim() === 'S' || datos_pro[2].trim() === 'C' ){
                    const formData = {
                        fechaRegistro: data.fechaRegistro,
                        serie: data.serie.toUpperCase(),
                        numero: data.numero,
                        moneda: data.moneda,
                        tipo: 'B',
                        cliente: cliente,
                        descripcion: data.descripcion,
                        tipoDoc: localStorage.getItem('tipoDocumentoFacIn'),
                        tipo_factura: data.tipo_factura ? data.tipo_factura : 'LOC',
                        establecimiento: id_emp[9],
                        modulo: 'FAC',
                        bodega: data.bodega.value,
                        producto: datos_pro[0].trim(),
                        cantidad: data.cantidad,
                        precio_unitario: data.precio,
                        total: parseFloat(localStorage.getItem('total_linea_factura')).toFixed(2),
                        id_compra: '',
                        costo_unitario_s_iva: 0.00,
                        total_s_iva: 0.00,
                        motivo: 'FACT',
                        descripcion: data.descripcion,
                        empresa: id_emp[5],
                        periodo: id_emp[7],
                        detalle: detalle,
                        detalleF: detalleF
                    }
                    
                    dispatch(setLoader(true));
                    api.post('/factura/factura_inventario/', formData).then((response) => {
                        
                        if (detalle){
                            response.serie = response.encabezado.serie;
                            response.numero = response.encabezado.numero;
                            response.fechaRegistro = response.encabezado.fecha_registro;
                            response.descripcion = response.encabezado.descripcion;
                            response.cliente_documento = response.encabezado.cliente
                            dispatch(initializeForm('FormInventarioFactura', response));
                            dispatch(leerDetalleProductosInv(response.detalle));
                        }else{
                            response.serie = response.encabezado.serie;
                            response.numero = response.encabezado.numero;
                            response.fechaRegistro = response.encabezado.fecha_registro;
                            response.descripcion = response.encabezado.descripcion;
                            response.cliente_documento = response.encabezado.cliente
                            localStorage.setItem('id_detalle_inventario_factura', response.detalle.encabezado);
                            localStorage.setItem('id_detalle_factura_salida', response.encabezado.id);
                            dispatch(initializeForm('FormInventarioFactura', response));
                            dispatch(leerDetalleProductosInv(response.detalle.encabezado));
                        }
                        NotificationManager.success(
                            'Producto ingresado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            0
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }else{
                    Swal.fire({
                        title: 'Facturación',
                        text: 'La cantidad ingresada es mayor a la existencia',
                        type: 'error',
                    })    
                }    
            }
    }else {
        Swal.fire({
            title: 'Facturación',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        const monedaNuevo = [];
        response.forEach(item => {
            monedaNuevo.push({
                value: item.id + '#' + item.simbolo,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
        dispatch({ type: LISTADO_MONEDAS_NUEVO, monedasNuevo: monedaNuevo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarProductoServicios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/producto/empresa_productos_cod',params).then(data=>{
        const list_producto = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'S'){
                    list_producto.push({
                        value: item.id,
                        label: item.SKU + ' / ' + item.producto
                    })
                }
            })
        }
        return list_producto ;
    }).catch((error)=>{
        return [];
    })
}

export const confirmarFactura = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    NotificationManager.success(
        'Factura guardada correctamente',
        'Exito',
        3000
    );
    localStorage.removeItem('id_detalle_inventario_factura');
    localStorage.removeItem('id_detalle_factura_salida');
    const editar = window.location.href.includes('editar');
    if (editar){
        let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`;
        dispatch(push(ruta));
    }else{
        window.location.reload(true);
    }
}

export const registroFacturaServicio = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaServicio.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    if (fecha>=fechaI && fecha<=fechaF){
        let cliente = data.cliente_documento;
        if (cliente === undefined){
            cliente = data.cliente;
        }else{
            cliente = data.cliente_documento.value;
        }
        const formData = {
            fechaRegistro: data.fechaRegistro,
            serie: data.serie.toUpperCase(),
            numero: data.numero,
            tipo: 'S',
            cliente: cliente,
            referencia: data.referencia,
            tipoDoc: localStorage.getItem('tipoDocumentoFacIn'),
            tipo_factura: data.tipo_factura ? data.tipo_factura : 'LOC',
            establecimiento: id_emp[9],
            modulo: 'FAC',
            descripcion: data.descripcion_serv,
            moneda: localStorage.getItem('monedaFacServicio') ? localStorage.getItem('monedaFacServicio') : '',
            motivo: 'FACT',
            empresa: id_emp[5],
            periodo: id_emp[7],
            detalle: data.detalleFacServ,
            tipo_cambio: localStorage.getItem('TC_facturaServicio'),
            cuenta: data.cuenta ? data.cuenta.value : '',
        }
        dispatch(setLoader(true));
        api.post('/factura/factura_servicio/', formData).then((response) => {
            response.serie = response.encabezado.serie;
            response.numero = response.encabezado.numero;
            response.fechaRegistro = response.encabezado.fecha_registro;
            response.referencia = response.encabezado.descripcion;
            response.codigo = response.encabezado.codigo;
            response.establecimiento = response.encabezado.establecimiento;
            response.id = response.encabezado.id;
            response.moneda = response.encabezado.moneda;
            response.cliente = response.encabezado.cliente;
            localStorage.setItem('id_detalle_servicio_factura', response.encabezado.id);
            dispatch(initializeForm('FormFacturaServicio', response));
            dispatch(leerDetalleFacturaServicio(response.encabezado.id));
            if (response.idPoliza){
                localStorage.setItem('conf_FacturaServicio', 'T');
                localStorage.setItem('id_poliza_servicio_factura', response.idPoliza);
                dispatch(leerDetalle(response.idPoliza));
            }else{
                window.location.reload(true);
            }
            NotificationManager.success(
                'Factura creada correctamente',
                'Exito',
                3000
            );
        }).catch((error) => {
            localStorage.setItem('conf_FacturaServicio', 'F')
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        localStorage.setItem('conf_FacturaServicio', 'F')
        Swal.fire({
            title: 'Facturación',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const leerFacturaServicio = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        if (response.tipo === 'S'){
            response.fechaRegistro = response.fecha_registro;
            response.referencia = response.descripcion;
            response.cliente = response.cliente;
            response.nombreCli = response.nombreCli;
            if (response.estatus === '**ANULADO**'){
                response.anulado = true;
            }
            dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
            localStorage.setItem('id_detalle_servicio_factura', response.id);
            dispatch(initializeForm('FormFacturaServicio', response));
            dispatch(leerDetalleFacturaServicio(response.id, response.empresa));
            if (response.poliza){
                localStorage.setItem('id_poliza_servicio_factura', response.poliza);
                dispatch(leerDetalleMod(response.poliza));
            }else{
                response.polizaDetalle_polizaEncabezado = [];
                dispatch({ type: GUARDAR_POLIZA, lectura: response });
            }
        }
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleFacturaServicio = id => (dispatch) => {
    api.get(`/factura_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS_SERVICIO, detalleProductoServicio: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const modificarFacturaServicio = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaServicio.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    if (fecha>=fechaI && fecha<=fechaF){
        const detalle = localStorage.getItem('id_detalle_servicio_factura');
        let cliente = data.cliente_documento;
        if (cliente === undefined){
            cliente = data.cliente;
        }else{
            cliente = data.cliente_documento.value;
        }
        const formData = {
            id: data.id,
            fechaRegistro: data.fechaRegistro,
            serie: data.serie.toUpperCase(),
            numero: data.numero,
            moneda: data.moneda,
            tipo: 'S',
            cliente: cliente,
            referencia: data.referencia,
            tipoDoc: localStorage.getItem('tipoDocumentoFacIn'),
            tipo_factura: data.tipo_factura ? data.tipo_factura : 'LOC',
            establecimiento: id_emp[9],
            tipo_cambio: data.tipo_cambio,
            modulo: 'FAC',
            empresa: id_emp[5],
            periodo: id_emp[7],
            detalle: detalle,
        }
        dispatch(setLoader(true));
        api.put('/factura/factura_servicio_update/', formData).then((response) => {
            NotificationManager.success(
                'Factura modificada correctamente',
                'Exito',
                3000
            );
            if (response.poliza){
                localStorage.setItem('conf_FacturaServicio', 'T');
            }else{
                let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`
                dispatch(push(ruta));
            }
        }).catch((error) => {
            localStorage.setItem('conf_FacturaServicio', 'F');
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
        
    }else {
        Swal.fire({
            title: 'Facturación',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const modificarProductoInventario = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormInventarioFactura.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    if (fecha>=fechaI && fecha<=fechaF){
        const detalle = localStorage.getItem('id_detalle_inventario_factura');
        const detalleF = localStorage.getItem('id_detalle_factura_salida');
        let cliente = data.cliente_documento;
        if (cliente === undefined){
            cliente = data.cliente;
        }else{
            cliente = data.cliente_documento.value;
        }
        const formData = {
            id: data.id,
            fechaRegistro: data.fechaRegistro,
            serie: data.serie.toUpperCase(),
            numero: data.numero,
            moneda: data.moneda,
            tipo: 'B',
            cliente: cliente,
            descripcion: data.descripcion,
            tipoDoc: localStorage.getItem('tipoDocumentoFacIn'),
            tipo_factura: data.tipo_factura ? data.tipo_factura : 'LOC',
            establecimiento: id_emp[9],
            modulo: 'FAC',
            id_compra: '',
            costo_unitario_s_iva: 0.00,
            total_s_iva: 0.00,
            motivo: 'FACT',
            empresa: id_emp[5],
            periodo: id_emp[7],
            detalle: detalle,
            detalleF: detalleF
        }
        dispatch(setLoader(true));
        api.put('/factura/factura_inventario_update/', formData).then((response) => {
            NotificationManager.success(
                'Factura modificada correctamente',
                'Exito',
                3000
            );
            let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
        
    }else {
        Swal.fire({
            title: 'Facturación',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const anularFac = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idFac = id;
    const params = { idE, idFac };
    dispatch(setLoader(true));
    api.put('/factura/anular/', params).then(() => {
        NotificationManager.success(
            'Factura anulado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {
        let tableRows = '';
        error.msj.forEach(item => {
            tableRows += `<tr><td>${item.formato_fecha}</td><td>${item.serie}</td><td>${item.numero}</td><td>${item.tipo}</td></tr>`;
        })
        Swal.fire({
            title: 'Abonos aplicados',
            html: `
                <table>
                    <thead>
                        <tr>
                            <th style="width: 100px">Fecha</th>
                            <th style="width: 100px">Serie</th>
                            <th style="width: 100px">Número</th>
                            <th style="width: 100px">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tableRows}
                    </tbody>
                </table>
            `,
            icon: 'warning',
            showCloseButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar',
        }).then((result) => {
            if (result.isConfirmed) {
                
            }
        });
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const anularFacFel = (id, mensaje) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idFac = id;
    const params = { idE, idFac, mensaje };
    dispatch(setLoader(true));
    api.put('/factura/anular_fel/', params).then(() => {
        NotificationManager.success(
            'Factura anulado correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {
        let tableRows = '';
        error.msj.forEach(item => {
            tableRows += `<tr><td>${item.formato_fecha}</td><td>${item.serie}</td><td>${item.numero}</td><td>${item.tipo}</td></tr>`;
        })
        Swal.fire({
            title: 'Abonos aplicados',
            html: `
                <table>
                    <thead>
                        <tr>
                            <th style="width: 100px">Fecha</th>
                            <th style="width: 100px">Serie</th>
                            <th style="width: 100px">Número</th>
                            <th style="width: 100px">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${tableRows}
                    </tbody>
                </table>
            `,
            icon: 'warning',
            showCloseButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar',
        }).then((result) => {
            if (result.isConfirmed) {
                
            }
        });
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroClienteServicio = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServicio.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S' && data.tipo_cliente === 'L'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        if (datos.validar_dpi === 'S' && data.tipo_cliente === 'P'){
            let valid = validar.valCui(data.identificador);
            if (valid[0].validar === false){
                Swal.fire({
                    title: 'Factura',
                    text: 'DPI con formato inválido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccionCli,
                    telefono: data.telefonoCli,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
        if (datos.validar_nit === 'S' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const registroClienteBienInv = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormInventarioFactura.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S' && data.tipo_cliente === 'L'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        if (datos.validar_dpi === 'S' && data.tipo_cliente === 'P'){
            let valid = validar.valCui(data.identificador);
            if (valid[0].validar === false){
                Swal.fire({
                    title: 'Factura',
                    text: 'DPI con formato inválido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccionCli,
                    telefono: data.telefonoCli,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
        if (datos.validar_nit === 'S' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const tipoCambio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturas.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fechaRegistro === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fechaRegistro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );
                                            return response;
                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

const actualizarServ = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServicio.values;
    dispatch(initializeForm('FormFacturaServicio', data));
    dispatch({ type: DETALLE_FACTURA, detalleFac: data.detalleFacServ })
};

let detalleFacServ = [];
export const agregarLineaServicio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServicio.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.descripcion_serv === undefined || data.descripcion_serv === 0.00) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar descripción',
                type: 'error',
            })
        } else {
            if (data.cantidad === undefined || data.cantidad === 0.000000) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar cantidad',
                    type: 'error',
                })
            } else {
                if (data.precio === undefined || data.precio === 0.00) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar precio venta',
                        type: 'error',
                    })
                } else {
                    detalleFacServ.push({
                        id: data.cantidad+'#'+data.descripcion_serv+'#'+data.precio,
                        desc: data.descripcion_serv,
                        cantidad: data.cantidad,
                        costo: data.precio,
                        total: parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio)).toFixed(2)
                    })
                    data.cantidad = 0;
                    data.descripcion_serv = '';
                    data.precio = 0.00;
                    data.detalleFacServ = detalleFacServ;
                    NotificationManager.success(
                        'Linea ingresada correctamente',
                        'Exito',
                        3000
                    );
                    dispatch(actualizarServ());
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const nuevaLineaServicio = () => (dispatch,getStore) => {
    const data = getStore().form.FormFacturaServicio.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (data.descripcion_serv === undefined || data.descripcion_serv === 0.00) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar descripción',
            type: 'error',
        })
    } else {
        if (data.cantidad === undefined || data.cantidad === 0.000000) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        } else {
            if (data.precio === undefined || data.precio === 0.00) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar precio venta',
                    type: 'error',
                })
            } else {
                const formData = {
                    id: data.id,
                    desc: data.descripcion_serv,
                    cantidad: data.cantidad,
                    precio: data.precio,
                    total: parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio)).toFixed(2),
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/factura_detalle/', formData).then((response) => {
                    NotificationManager.success(
                        'Servicio ingresado correctamente',
                        'Exito',
                        3000
                    );
                    dispatch(leerDetalleFacturaServicio(response.id));
                    if (response.poliza){
                        dispatch(leerDetalleMod(response.poliza));
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            } 
        }
    }
}

export const imprimirFactura = id => () => {
    window.open(`https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=${id}`, "_blank");
    
}

export const anularFacFelInfile = (id, mensaje) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idFac = id;
    const params = { idE, idFac, mensaje };
    dispatch(setLoader(true));
    api.put('/factura/anular_fel_infile/', params).then(() => {
        NotificationManager.success(
            'Factura anulado correctamente',
            'Exito',
            3000
        );
        dispatch(listar());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const tipoCambioInventarioServ = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServicio.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fechaRegistro === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fechaRegistro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );
                                            return response;
                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}

const eliminarLineaPolizaServicio = id => (dispatch) => {
    const poliza = localStorage.getItem('id_poliza_servicio_factura');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const vaciarServicios = () => (dispatch) => {
    detalleFacServ = [];
    dispatch(initializeForm('FormFacturaServicio'));
    dispatch({ type: DETALLE, detalleFac: detalleFacServ });
};

const actualizarFormServicio = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServicio.values;
    dispatch(initializeForm('FormFacturaServicio', data));
    dispatch({ type: DETALLE, detalleFac: data.detalleFacServ })
};

const eliminarLineaServ = (id) => (dispatch) => {
    let datos = id.split('#');
    detalleFacServ.forEach((element, index) => {
        if (element.cantidad === datos[0] && element.desc === datos[1] && element.costo === datos[2]) {
            detalleFacServ.splice(index, 1);
        }
    })
    dispatch(actualizarFormServicio());
};

export const actions = {
    listar,
    listarFacSaldos,
    registroFactura,
    modificarFactura,
    leer,
    leerSaldo,
    listarTiposDocu,
    listarClientes,
    leerDetalle,
    eliminarLinea,
    listarCuentas,
    listarCC,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalleSCCServicio,
    confirmarPoliza,
    registroPolizaDetalleSCC,
    eliminarFacturaSaldo,
    eliminarFacturaSFEL,
    registroCliente,
    listarPaises,
    registroFacturaSaldo,
    modificarFacturaSaldo,
    listarEstablecimientos,
    registroClienteSaldo,
    getModulo,
    leerPeriodoD,
    parametrosEmpresa,
    listarImpuestos,
    leerEstablecimiento,
    listarBodegas,
    listarProductos,
    leerDetalleProductos,
    eliminarLineaProducto,
    leerProductos,
    registroProductoInventario,
    modificarProductoInventario,
    listarMonedas,
    listarProductoServicios,
    confirmarFactura,
    leerInvBienes,
    registroFacturaServicio,
    modificarFacturaServicio,
    leerFacturaServicio,
    leerDetalleFacturaServicio,
    eliminarLineaProductoServicio,
    anularFac,
    anularFacFel,
    parametrosEmpresaValidar,
    registroClienteServicio,
    registroClienteBienInv,
    tipoCambio,
    agregarLineaServicio,
    nuevaLineaServicio,
    imprimirFactura,
    anularFacFelInfile,
    tipoCambioInventarioServ,
    eliminarLineaPolizaServicio,
    registroPolizaDetalleCCServicio,
    registroPolizaDetalleCC,
    vaciarServicios,
    eliminarLineaServ,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FACTURA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [LISTADO_CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [PERIODO]: (state, { periodoS }) => {
        return {
            ...state,
            periodoS,
        };
    },
    [GUARDAR_FACTURA]: (state, { lecturaFac }) => {
        return {
            ...state,
            lecturaFac,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [LISTADO_IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [DETALLE_PRODUCTOS_SERVICIO]: (state, { detalleProductoServicio }) => {
        return {
            ...state,
            detalleProductoServicio,
        };
    },
    [LISTADO_FACTURA_SALDOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_FACTURA]: (state, { detalleFac }) => {
        return {
            ...state,
            detalleFac,
        };
    },
    [LISTADO_MONEDAS_NUEVO]: (state, { monedasNuevo }) => {
        return {
            ...state,
            monedasNuevo,
        };
    },
    [DETALLE]: (state, { detalleFac }) => {
        return {
            ...state,
            detalleFac,
        };
    }
};

export const initialState = {
    loader: false,
    tipos: [],
    cli: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    periodoS: null,
    nivel: [],
    loaderC: false,
    detalle: [],
    listaEs: [],
    modulo: [],
    datos: [],
    pro: [],
    periodoD: [],
    impuesto: [],
    estable: [],
    monedas: [],
    detalleProductoServicio: [],
    detalleProducto: [],
    data: [],
    page: 1,
    detalleFac: [],
    lecturaFac: [],
    monedasNuevo: []
};

export default handleActions(reducers, initialState)