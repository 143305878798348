import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_FACTURA = 'LISTADO_FACTURA';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const PARAMETRO = 'PARAMETRO';
const DETALLE_FACTURA = 'DETALLE_FACTURA';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const DETALLE = 'DETALLE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const NIVEL = 'NIVEL';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LOADERC = 'LOADERC';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/factura_especial', params).then((response) => {
        dispatch({ type: LISTADO_FACTURA, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar facturas especiales',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura_especial/${id}`).then((response) => {
        response.fechaRegistro = response.fecha_registro;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lectura: response });
        dispatch(initializeForm('FormFacturaEspecialFEL', response));
        dispatch(leerDetalleFactura(response.id));
        localStorage.setItem('idPolizaFacturaEspecial', response.poliza);
        dispatch(leerDetallePolizaMod(response.poliza));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleFactura = (id) => (dispatch) => {
    api.get(`/factura_especial_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_FACTURA, detalleFactura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const getModulo = (id, modulo) => (dispatch) => {
    const params = { id, modulo }
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response) => {
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const registroFactura = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaEspecialFEL.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.tipo_detalle === undefined || data.tipo_detalle === '') {
            Swal.fire({
                title: 'Factura especial',
                text: 'Debe de seleccionar tipo factura ( Bienes o servicios )',
                type: 'error',
            })
        } else {
            let fechaActual = moment();
            let diferenciaDias = fechaActual.diff(fecha, 'days');
                    
            if (diferenciaDias >= 6) {
                localStorage.setItem('conf_fact_esp','F');
                Swal.fire({
                    title: 'Factura especial',
                    text: 'Fecha es mayor a 5 dias permitidos por la SAT',
                    type: 'error',
                })
            } else {
                let total = 0.00;
                data.detalleFac.forEach( (e) => { 
                    total += parseFloat(e.total);
                });

                const formData = {
                    fecha_registro: data.fechaRegistro,
                    tipo_detalle: data.tipo_detalle,
                    observaciones: data.observaciones,
                    idProveedor: data.proveedor_documento.value,
                    detalleFactura: data.detalleFac,
                    total: total,
                    establecimiento: id_emp[9],
                    motivo: 'FAC',
                    empresa: id_emp[5],
                    periodo: id_emp[7],
                    poliza: 'factura_especial'
                }
                if (parseInt(datos.certificador) === 1){
                    dispatch(setLoader(true));
                    api.post('/factura_especial/', formData).then((response) => {
                        response.fechaRegistro = response.encabezado.fecha_registro;
                        response.observaciones = response.encabezado.descripcion;
                        dispatch(leerDetallePoliza(response.idPoliza));
                        dispatch(initializeForm('FormFacturaEspecialFEL', response));
                        localStorage.setItem('idPolizaFacturaEspecial', response.idPoliza)
                        localStorage.setItem('conf_fact_esp','T');

                        NotificationManager.success(
                            'Factura guardada correctamente',
                            'Exito',
                            3000
                        );

                    }).catch((error) => {
                        localStorage.setItem('conf_fact_esp','F');
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            4000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }else if (parseInt(datos.certificador) === 2){
                    dispatch(setLoader(true));
                    api.post('/factura_especial/certificador_infile/', formData).then((response) => {
                        response.fechaRegistro = response.encabezado.fecha_registro;
                        response.observaciones = response.encabezado.descripcion;
                        dispatch(leerDetallePoliza(response.idPoliza));
                        dispatch(initializeForm('FormFacturaEspecialFEL', response));
                        localStorage.setItem('idPolizaFacturaEspecial', response.idPoliza)
                        localStorage.setItem('conf_fact_esp','T');

                        NotificationManager.success(
                            'Factura guardada correctamente',
                            'Exito',
                            3000
                        );

                    }).catch((error) => {
                        localStorage.setItem('conf_fact_esp','F');
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            4000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    } else {
        localStorage.setItem('conf_fact_esp','F');
        Swal.fire({
            title: 'Factura especial',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id }
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({ type: PARAMETRO, datos: response });
    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({ type: ESTABLECIMIENTO, estable: response });

    }).catch((error) => {

        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data) => {
        const estable = [];
        data.results.forEach(item => {
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1) {
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        } else {
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }

    }).catch(error => {

        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

let detalleFac = [];
export const agregarLinea = (periodoD, total) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaEspecialFEL.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.cantidad === undefined || data.cantidad === 0) {
            Swal.fire({
                title: 'Factura especial',
                text: 'Debe de ingresar cantidad',
                type: 'error',
            })
        } else {
            if (data.descripcion === undefined || data.descripcion === '') {
                Swal.fire({
                    title: 'Factura especial',
                    text: 'Debe de ingresar descripción',
                    type: 'error',
                })
            } else {

                if (data.precio === undefined || data.precio === 0.00) {
                    Swal.fire({
                        title: 'Factura especial',
                        text: 'Debe de ingresar precio',
                        type: 'error',
                    })
                } else {
                    let fechaActual = moment();
                    let diferenciaDias = fechaActual.diff(fecha, 'days');
                    
                    if (diferenciaDias >= 6) {
                        Swal.fire({
                            title: 'Factura especial',
                            text: 'Fecha es mayor a 5 dias permitidos por la SAT',
                            type: 'error',
                        })
                    } else {
                        detalleFac.push({
                            id: data.cantidad + '-' + total,
                            cantidad: data.cantidad,
                            desc: data.descripcion,
                            precio: data.precio,
                            total: total,
                        })
                        data.cantidad = 0;
                        data.descripcion = '';
                        data.detalleFac = detalleFac;
                        NotificationManager.success(
                            'Linea ingresada correctamente',
                            'Exito',
                            3000
                        );
                        dispatch(actualizar());
                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Factura especial',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaEspecialFEL.values;
    dispatch(initializeForm('FormFacturaEspecialFEL', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleFac })
};

const vaciarDetalle = () => (dispatch) => {
    detalleFac = [];
    dispatch(initializeForm('FormFacturaEspecialFEL'));
    dispatch({ type: DETALLE, detalleCon: detalleFac });
};

const eliminarLinea = (id) => (dispatch) => {
    let buscar = id.split('-')
    detalleFac.forEach((element, index) => {
        if (parseFloat(element.cantidad) === parseFloat(buscar[0]) && parseFloat(element.total) === parseFloat(buscar[1])) {
            detalleFac.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response => {
        const pais = [];
        response.results.forEach(item => {
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error => {
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    })
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'facturaEspecial';
    const params = { id, search, tipo }
    return api.get('/proveedor/empresa_proveedores', params).then(data => {
        const list_proveedor = [];
        if (data) {
            data.forEach(item => {
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_proveedor;
    }).catch((error) => {

        return [];
    })
}


export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const listarImpuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    dispatch(setLoader(true));
    api.get('/impuesto/impuesto_empresa', params).then((response) => {
        dispatch({ type: LISTADO_IMPUESTO, impuesto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar impuesto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {

        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {

        return [];
    })
}

export const registroProveedor = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaEspecialFEL.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (data.tipo_documento === 'NIT') {
        if (datos.validar_nit === 'S') {
            let valid = validar.valNit(data.identificador);
            if (valid === false) {
                Swal.fire({
                    title: 'Proveedor',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            } else {
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: data.tipo_proveedor,
                    dias_credito: data.dias_credito,
                    modulo: 'CMP',
                    pais: data.pais,
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then(() => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        } else {
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                modulo: 'CMP',
                pais: data.pais,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );

            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
    if (data.tipo_documento === 'DPI') {
        if (datos.validar_dpi === 'S') {
            let valid = validar.valCui(data.identificador);

            if (valid[0].validar === false) {
                Swal.fire({
                    title: 'Proveedor',
                    text: valid[0].msj,
                    type: 'error',
                })
            } else {
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_proveedor: data.tipo_proveedor,
                    dias_credito: data.dias_credito,
                    modulo: 'CMP',
                    pais: data.pais,
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/proveedor/', formData).then(() => {
                    NotificationManager.success(
                        'Proveedor creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        0
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        } else {
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_proveedor: data.tipo_proveedor,
                dias_credito: data.dias_credito,
                modulo: 'CMP',
                pais: data.pais,
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/proveedor/', formData).then(() => {
                NotificationManager.success(
                    'Proveedor creado',
                    'Exito',
                    3000
                );

            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

const leerDetallePoliza = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


const leerDetallePolizaMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lecturaP: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    const data = getStore().form.FacturacionFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const poliza = localStorage.getItem('idPolizaFacturaEspecial');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaFacturaEspecial')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetallePoliza(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaFacturaEspecial')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetallePoliza(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FacturacionFormTabs.values;
    const poliza = localStorage.getItem('idPolizaFacturaEspecial');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    }else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaFacturaEspecial')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetallePoliza(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaFacturaEspecial')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FacturacionFormTabs', response));
                dispatch(leerDetallePoliza(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePolizaMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FacturacionFormTabs', response));
                    dispatch(leerDetallePoliza(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('idPolizaFacturaEspecial');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            ruta = `/compras/${id_emp[5]}/factura_especial_fel/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

export const registroCuenta = (formato) => (dispatch, getStore) => {
    const data = getStore().form.FacturacionFormTabs.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPolizaFacturaEspecial')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const anularFacFel = (id, mensaje, certificador) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idE = id_emp[5];
    let idFac = id;
    const params = { idE, idFac, mensaje };
    if (parseInt(certificador) === 1) {
        dispatch(setLoader(true));
        api.put('/factura_especial/anular_fel/', params).then(() => {
            NotificationManager.success(
                'Factura anulado correctamente',
                'Exito',
                3000
            );
            let page = 1;
            dispatch(listar());
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                6000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else if(parseInt(certificador) === 2) {
        dispatch(setLoader(true));
        api.put('/factura_especial/anular_fel_infile/', params).then(() => {
            NotificationManager.success(
                'Factura anulado correctamente',
                'Exito',
                3000
            );
            let page = 1;
            dispatch(listar());
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                6000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLineaPoliza = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaFacturaEspecial');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetallePolizaMod(poliza));
        } else {
            dispatch(leerDetallePoliza(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const imprimirFacturaEspecial = id => () => {
    window.open(`https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=${id}`, "_blank");
    
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroFactura,
    parametrosEmpresa,
    leerEstablecimiento,
    listarEstablecimientos,
    agregarLinea,
    actualizar,
    vaciarDetalle,
    eliminarLinea,
    listarPaises,
    listarProveedores,
    listarNiveles,
    listarImpuestos,
    listarCuentas,
    listarCC,
    registroProveedor,
    confirmarPoliza,
    registroPolizaDetalle,
    registroPolizaDetalleSCC,
    registroCuenta,
    reporteCuentas,
    leerDetalleFactura,
    leerDetallePoliza,
    leerDetallePolizaMod,
    anularFacFel,
    eliminarLineaPoliza,
    imprimirFacturaEspecial
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FACTURA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [GUARDAR_FACTURA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [DETALLE]: (state, { detalleCon }) => {
        return {
            ...state,
            detalleCon,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LISTADO_IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [DETALLE_FACTURA]: (state, { detalleFactura }) => {
        return {
            ...state,
            detalleFactura,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [GUARDAR_POLIZA]: (state, { lecturaP }) => {
        return {
            ...state,
            lecturaP,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    lecturaP: [],
    modulo: [],
    periodoD: [],
    detalleFactura: [],
    estable: [],
    listaEs: [],
    tipos: [],
    datos: [],
    detalle: [],
    nivel: [],
    pro: [],
    impuesto: [],
    loaderC: false,
    listaP: [],
    detalleCon: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)

