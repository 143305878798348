import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_FACTURA = 'LISTADO_FACTURA';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const DETALLE_ENVIO = 'DETALLE_ENVIO';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const PRODUCTOS = 'PRODUCTOS';
const DETALLE = 'DETALLE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADERC = 'LOADERC';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const leerB = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormFacturaBienesFel', response ));
        dispatch(leerDetalleProductos(response.id, response.empresa));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormFactura', response ));
        dispatch(leerDetalleProductosEnvio(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const listarEnvios = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/envio_produccion/empresa_envio_produccionFactura',params).then(data=>{
        const list_envios = [];
        if(data){
            data.forEach(item=>{
                list_envios.push({
                    value: item.id,
                    label: item.serie + ' - ' + item.numero
                })
            })
        }
        return list_envios ;
    }).catch((error)=>{
        return [];
    })
}

export const registroFactura = (periodoD, idCliente, detalleN, tipoD, iva, exportacion, tipoCambio) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFactura.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        let total = 0.00;
        detalleN.forEach( (e) => { 
            if (parseFloat(e.unidad) === 0.00) {
                total += parseFloat(parseFloat(e.cantidad)*parseFloat(e.precio));
            }else{
                total += parseFloat(parseFloat(e.unidad)*parseFloat(e.precio));
            }
        });
        
        const formData = {
            fechaRegistro: data.fechaRegistro,
            tipoD: tipoD,
            iva: iva,
            observaciones: data.observaciones,
            idEnvio: data.envio.value,
            idCliente: idCliente,
            detalleFactura: detalleN,
            establecimiento: id_emp[9],
            total: total,
            motivo: 'FACT',
            envio: 'envio',
            empresa: id_emp[5],
            periodo: id_emp[7],
            exportacion: exportacion,
            tipoCambio: tipoCambio,
            refExport: data.ref_exportacion,
            en_dolares: exportacion ? true : false,
            tipoFactura: exportacion ? 'EXP' : 'LOC'
        }
        
        dispatch(setLoader(true));
        api.post('/factura/factura_inventario_prd_fel/', formData).then(() => {
            NotificationManager.success(
                'Factura guardada correctamente',
                'Exito',
                3000
            );
            setTimeout(function(){
                window.location.reload(true);
            }, 2000);
            
        }).catch((error)=>{
            NotificationManager.error(
                error.msj,
                'Error',
                4000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleProductos = ( id, empresa ) => (dispatch) => {
    const params = { id, empresa };
    api.get('/movimiento_inventario/movimiento_inventario_factura', params).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response.data });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleProductosEnvio = id => (dispatch) => {
    api.get(`factura_detalle_prd/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: [] });;
    })
};


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

let detalle = [];
export const detalleEnvio = (id) => (dispatch) => {
    let params = {id}
    return api.get('/envio_produccion_detalle/detalle_envio', params).then((response)=>{
        dispatch({type: CLIENTE, cli: response });
        detalle=[]
        response.detalle.forEach(data => {
            detalle.push({
                id: data.id,
                cantidad: data.cantidad,
                sku: data.sku,
                producto: data.producto,
                productoN: data.productoN,
                unidad: data.unidad,
                insumo: data.insumo,
                cantidad_insumo: data.cantidad_insumo,
                precio: data.precioFormato,
                total: data.total
            })
        });
        dispatch({ type: DETALLE_ENVIO, detalleEnv: detalle });
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
}

export const eliminarFactura = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/factura/${id}`).then(() => {
        NotificationManager.success(
            'Factura borrada correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al borrar la factura',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormFactura'));
    dispatch({ type: DETALLE_ENVIO, detalleEnv: detalle });
};

export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({type: ESTABLECIMIENTO, estable: response });
        
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        const estable = [];
        data.results.forEach(item=>{
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1 ){
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        }else{
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
        
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tiposFac', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const leerProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = {id, idEmpre }
    api.get('/producto/empresa_productos_cod_inventario_bodega_prd/',params).then((response) => {
        const producto = [];
        response.forEach(item=>{
            if (item.tipoPro === 'P'){
                producto.push({
                    value: item.id+' # '+ item.saldo + '#' + item.tipoPro,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            }
        })
        dispatch({type: PRODUCTOS, pro: producto })
    })
}

let detalleFac = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFel.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.bodega === undefined || data.bodega === 0.00) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de seleccionar bodega',
                type: 'error',
            })
        } else {
            if (data.producto === undefined || data.producto === null) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de seleccionar producto',
                    type: 'error',
                })
            } else {
                if (data.cantidad === undefined || data.cantidad === 0.000000) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar cantidad',
                        type: 'error',
                    })
                } else {
                    if (data.precioVenta === undefined || data.precioVenta === 0.00) {
                        Swal.fire({
                            title: 'Factura',
                            text: 'Debe de ingresar precio venta',
                            type: 'error',
                        })
                    } else {
                        
                        let proN = data.producto.label.split("--");
                        let proID = proN[0].split("/");
                        let pro = data.producto.value.split('#');
                        let numero_bodega = data.bodega.label.split('/');
                            
                        if (parseFloat(data.cantidad) > parseFloat(pro[1].trim())){
                            Swal.fire({
                                title: 'Factura',
                                text: 'Cantidad ingresada es mayor a la existencia',
                                type: 'error',
                            })
                        }else {
                            let unidad = 0;
                            if (data.unidad === undefined || data.unidad === 0){
                                unidad = 0
                            }else{
                                unidad = data.unidad
                            }

                            detalleFac.push({
                                id: pro[0].trim(),
                                cantidad: data.cantidad,
                                idPro: pro[0].trim(),
                                sku: proID[0].trim(),
                                productoN: proID[1].trim(),
                                idBodega: data.bodega.value, 
                                bodega: numero_bodega[0],
                                precio: data.precioVenta,
                                unidad: unidad,
                                total: unidad === 0 ? parseFloat(parseFloat(data.cantidad)*parseFloat(data.precioVenta)).toFixed(2) : parseFloat(parseFloat(data.unidad)*parseFloat(data.precioVenta)).toFixed(2)
                            })
                            data.cantidad = 0;
                            data.unidad = 0;
                            data.precioVenta = 0.00;
                            data.detalleFac = detalleFac;
                            NotificationManager.success(
                                'Producto ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }
                    }
                }
            }
        }

    } else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFel.values;
    dispatch(initializeForm('FormFacturaBienesFel', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleFac })
};

const vaciarBienes = () => (dispatch) => {
    detalleFac = [];
    dispatch(initializeForm('FormFacturaBienesFel'));
    dispatch({ type: DETALLE, detalleCon: detalleFac });
};

const eliminarLinea = (id) => (dispatch) => {
    detalleFac.forEach((element, index) => {
        if (element.idPro === id) {
            detalleFac.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const registroFacturaBienes = (periodoD, detalleFac, iva, tipoD, tipoCambio, tipoM, tipoFac) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaBienesFel.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        let total = 0.00;
        detalleFac.forEach( (e) => { 
            if (parseFloat(e.unidad) === 0.00) {
                total += parseFloat(parseFloat(e.cantidad)*parseFloat(e.precio));
            }else{
                total += parseFloat(parseFloat(e.unidad)*parseFloat(e.precio));
            }
        });
        if (tipoFac === 'EXP' && tipoCambio === 1.000000){
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar tipo de cambio',
                type: 'error',
            })   
        }else{
            if ((data.ref_exportacion === '' || data.ref_exportacion === undefined) && (tipoFac === 'EXP')){
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar referencia para exportación',
                    type: 'error',
                })    
            }else{
                const formData = {
                    fechaRegistro: data.fecha,
                    tipoD: tipoD,
                    iva: iva,
                    observaciones: data.descripcion,
                    idCliente: data.cliente_documento.value,
                    detalleFactura: detalleFac,
                    establecimiento: id_emp[9],
                    total: total,
                    motivo: 'FACT',
                    envio: 'sin_envio',
                    empresa: id_emp[5],
                    periodo: id_emp[7],
                    exportacion: tipoFac === 'EXP' ? true : false,
                    tipoCambio: tipoCambio,
                    refExport: data.ref_exportacion,
                    en_dolares: tipoFac === 'EXP' ? true : false,
                    moneda: tipoM,
                    tipoFactura: tipoFac
                }
                
                dispatch(setLoader(true));
                api.post('/factura/factura_inventario_prd_fel/', formData).then(() => {
                    NotificationManager.success(
                        'Factura guardada correctamente',
                        'Exito',
                        3000
                    );
                    setTimeout(function(){
                        window.location.reload(true);
                    }, 1000);
                    
                }).catch((error)=>{
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        4000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const tipoCambio = (periodoD, exportacion) => (dispatch, getStore) => {
    const data = getStore().form.FormFactura.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        if (exportacion){
            let fechaI = moment(periodoD.fecha_inicio);
            let fechaF = moment(periodoD.fecha_fin);
            let fecha = moment(data.fechaRegistro)
            if (fecha>=fechaI && fecha<=fechaF){
                if (data.fechaRegistro === undefined) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar fecha de la factura',
                        type: 'error',
                    })
                } else {
                    let fecha = data.fechaRegistro;
                    let ruta = window.location.href;
                    let id_emp = ruta.split('/');
                    let idEmpre = id_emp[5];
                    const params = { fecha, idEmpre }
                    dispatch(setLoaderC(true));
                    return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                        if (parseFloat(response) === 0) {
                            Swal.fire({
                                title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                                html:
                                    '<div class="col-sm-12 mt-4">' +
                                    '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                    '</div>',
                                type: 'info',
                                background: "black",
                                showCancelButton: true,
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No',
                                confirmButtonColor: "#4040ff",
                                cancelButtonColor: "#646464",
                                reverseButtons: true,
                                customClass: {
                                    title: 'sweet_titleImportant',
                                },
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Ingrese tipo de cambio',
                                        html:
                                            '<div class="col-sm-12 mt-3">' +
                                            '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                            '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                            '</div>',
                                        type: 'info',
                                        background: "black",
                                        showCancelButton: true,
                                        confirmButtonText: 'Si',
                                        cancelButtonText: 'No',
                                        confirmButtonColor: "#4040ff",
                                        cancelButtonColor: "#646464",
                                        reverseButtons: true,
                                        customClass: {
                                            title: 'sweet_titleImportant',
                                        },
                                    }).then((result) => {
                                        if (result.value) {
                                            let tcambio = document.getElementById('cambio').value
                                            const formData = {
                                                fechaT: fecha,
                                                tipoCambio: tcambio,
                                                empresa: id_emp[5],
                                                tipo: 'manual'
                                            }
                                            api.post('/tipo_cambio/', formData).then(response => {
                                                NotificationManager.success(
                                                    'Tipo de cambio guardado',
                                                    'Exito',
                                                    4000
                                                );

                                            }).catch((error) => {
                                                NotificationManager.error(
                                                    error.msj,
                                                    'Error',
                                                    0
                                                );
                                            })
                                        }
                                    });
                                }
                            });
                        } else {
                            return response;
                        }
                    }).catch((error) => {
                        NotificationManager.error(
                            'Ocurrió un error al consultar tipo de cambio',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoaderC(false));
                    });
                }    
            }else{
                Swal.fire({
                    title: 'Factura',
                    text: 'Fecha fuera del periodo',
                    type: 'error',
                })        
            }
        }
    }
}

export const tipoCambioB = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFel.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fecha === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
        
    }
}


let detalleFacServ = [];
export const agregarLineaServ = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServiciosFel.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);

    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.descripcion_serv === undefined || data.descripcion_serv === 0.00) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar descripción',
                type: 'error',
            })
        } else {
            if (data.cantidad === undefined || data.cantidad === 0.000000) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar cantidad',
                    type: 'error',
                })
            } else {
                if (data.precio === undefined || data.precio === 0.00) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar precio venta',
                        type: 'error',
                    })
                } else {
                    detalleFacServ.push({
                        id: data.cantidad,
                        desc: data.descripcion_serv,
                        cantidad: data.cantidad,
                        costo: data.precio,
                        total: parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio)).toFixed(2)
                    })
                    data.cantidad = 0;
                    data.descripcion_serv = '';
                    data.precio = 0.00;
                    data.detalleFacServ = detalleFacServ;
                    NotificationManager.success(
                        'Producto ingresado correctamente',
                        'Exito',
                        3000
                    );
                    dispatch(actualizarServ());
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizarServ = () => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServiciosFel.values;
    dispatch(initializeForm('FormFacturaServiciosFel', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleFacServ })
};

const vaciarServicios = () => (dispatch) => {
    detalleFacServ = [];
    dispatch(initializeForm('FormFacturaServiciosFel'));
    dispatch({ type: DETALLE, detalleCon: detalleFacServ });
};

const eliminarLineaServ = (id) => (dispatch) => {
    detalleFacServ.forEach((element, index) => {
        if (element.idPro === id) {
            detalleFacServ.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

export const registroFacturaServicio = (periodoD, detalleN, iva, tipoD, tipoCambio, tipoM, tipoFac) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormFacturaServiciosFel.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    if (fecha>=fechaI && fecha<=fechaF){
        let total = 0.00;
        detalleN.forEach( (e) => { 
            total += parseFloat(e.total);
        });
        if (tipoFac === 'EXP' && tipoCambio === 1.000000){
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar tipo de cambio',
                type: 'error',
            })   
        }else{
            if ((data.ref_exportacion === '' || data.ref_exportacion === undefined) && (tipoFac === 'EXP')){
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar referencia para exportación',
                    type: 'error',
                })    
            }else{
                const formData = {
                    fechaRegistro: data.fecha,
                    tipoD: tipoD,
                    iva: iva,
                    observaciones: data.descripcion,
                    idCliente: data.cliente_documento.value,
                    detalleFactura: detalleN,
                    establecimiento: id_emp[9],
                    total: total,
                    motivo: 'FACT',
                    empresa: id_emp[5],
                    periodo: id_emp[7],
                    exportacion: tipoFac === 'EXP' ? true : false,
                    tipoCambio: tipoCambio,
                    refExport: tipoFac,
                    en_dolares: data.tipo_factura === 'EXP' ? true : false,
                    moneda: tipoM,
                    tipoFactura: tipoFac
                }
                
                dispatch(setLoader(true));
                api.post('/factura/factura_servicio_fel/', formData).then(() => {
                    NotificationManager.success(
                        'Factura guardada correctamente',
                        'Exito',
                        3000
                    );
                    setTimeout(function(){
                        window.location.reload(true);
                    }, 2000);
                    
                }).catch((error)=>{
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        4000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const tipoCambioServ = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaServiciosFel.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fecha)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fecha === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fecha;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
        
    }
}


export const leerS = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormFacturaServiciosFel', response ));
        dispatch(leerDetalleServicios(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleServicios = (id) => (dispatch) => {
    api.get(`/factura_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCliente = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormFacturaBienesFel.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        const formData = {
            identificador: data.identificador,
            nombre: data.nombre,
            direccion: data.direccion,
            telefono: data.telefono,
            correo: data.correo,
            limite: data.limite_credito,
            tipo_cliente: data.tipo_cliente,
            dias_credito: data.dias_credito,
            pais: data.pais,
            modulo: 'FAC',
            empresa: id_emp[5]
        }
        dispatch(setLoader(true));
        api.post('/cliente/', formData).then(() => {
            NotificationManager.success(
                'Cliente creado',
                'Exito',
                3000
            );
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}


export const actions = {
    leer,
    leerB,
    leerS,
    getModulo,
    leerPeriodoD,
    registroFactura,
    listarEnvios,
    parametrosEmpresa,
    detalleEnvio,
    leerDetalleProductos,
    eliminarFactura,
    vaciar,
    leerEstablecimiento,
    listarEstablecimientos,
    listarTiposDocu,
    listarBodegas,
    leerProductos,
    agregarLinea,
    actualizar,
    vaciarBienes,
    eliminarLinea,
    listarPaises,
    listarClientes,
    registroFacturaBienes,
    tipoCambio,
    tipoCambioB,
    agregarLineaServ,
    vaciarServicios,
    eliminarLineaServ,
    registroFacturaServicio,
    tipoCambioServ,
    listarMonedas,
    registroCliente
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FACTURA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [DETALLE_ENVIO]: (state, { detalleEnv }) => {
        return {
            ...state,
            detalleEnv,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [GUARDAR_FACTURA]: (state, { lecturaFac }) => {
        return {
            ...state,
            lecturaFac,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [DETALLE]: (state, { detalleCon }) => {
        return {
            ...state,
            detalleCon,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    cli: [],
    detalleProducto: [],
    detalleEnv: [],
    estable: [],
    listaEs: [],
    tipos: [],
    datos: [],
    lectura: [],
    pro: [],
    loaderC: false,
    listaP: [],
    monedas: [],
    detalleCon: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    lecturaFac: [],
};

export default handleActions(reducers, initialState)

    