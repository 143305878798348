import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
import { validar } from "../../../common/components/components/ValidarNit";

const LISTADO_FACTURA = 'LISTADO_FACTURA';
const GUARDAR_FACTURA = 'GUARDAR_FACTURA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const CLIENTE = 'CLIENTE';
const ESTABLECIMIENTO = 'ESTABLECIMIENTO';
const LISTADO_ESTABLECIMIENTOS = 'LISTADO_ESTABLECIMIENTOS';
const LISTADO_TIPOS_DOCU = 'LISTADO_TIPOS_DOCU';
const PRODUCTOS = 'PRODUCTOS';
const DETALLE = 'DETALLE';
const LISTADO_PAISES = 'LISTADO_PAISES';
const LOADERC = 'LOADERC';
const LISTADO_MONEDAS = 'LISTADO_MONEDAS';
const NIVEL = 'NIVEL';
const LISTADO_IMPUESTO = 'LISTADO_IMPUESTO';
const DETALLE_PRODUCTOS_SERVICIO = 'DETALLE_PRODUCTOS_SERVICIO';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LISTADO_MONEDAS_NUEVO = 'LISTADO_MONEDAS_NUEVO';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/factura/${id}`).then((response) => {
        response.fechaRegistro = response.fecha;
        response.observaciones = response.descripcion;
        response.clienteN = response.nombreCli;
        response.correlativo = response.serie + ' - ' + response.numero;
        response.serie = response.serie_FEL;
        response.numero = response.numero_FEL;
        response.autorizacion = response.numero_autorizacion;
        dispatch({ type: GUARDAR_FACTURA, lecturaFac: response });
        dispatch(initializeForm('FormBienesServiciosFEL', response ));
        dispatch(leerDetalleFactura(response.id));
        if (response.poliza){
            localStorage.setItem('id_poliza_bien_servicio_factura', response.poliza);
            dispatch(leerDetalleMod(response.poliza));
        }else{
            response.polizaDetalle_polizaEncabezado = [];
            dispatch({ type: GUARDAR_POLIZA, lectura: response });
        }
        
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar factura',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const registroFactura = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormBienesServiciosFEL.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro)
    if (fecha>=fechaI && fecha<=fechaF){
        let total = 0.00;
        data.detalleFac.forEach( (e) => { 
            total += parseFloat(parseFloat(e.cantidad)*parseFloat(e.precio));
        });
        
        const formData = {
            fecha_registro: data.fechaRegistro,
            cliente: data.cliente_documento.value,
            referencia: data.referencia,
            tipo: data.tipo_factura,
            moneda: localStorage.getItem('monedaFacServicio'),
            establecimiento: id_emp[9],
            tipo_documento: localStorage.getItem('tipoDocumentoFacBS'),
            total: total,
            detalleFac: data.detalleFac,
            empresa: id_emp[5],
            periodo: id_emp[7],
            tipo_cambio: localStorage.getItem('TC_facturaBienesServicio'),
            cuenta: data.cuenta ? data.cuenta.value : '',
        }
        
        dispatch(setLoader(true));
        api.post('/factura/factura_bienes_servicios_fel/', formData).then((response) => {
            NotificationManager.success(
                'Factura guardada correctamente',
                'Exito',
                3000
            );
            if (response.idPoliza){
                localStorage.setItem('conf_FacturaBienServicioFel', 'T');
                localStorage.setItem('id_poliza_bien_servicio_factura', response.idPoliza);
                dispatch(leerDetalle(response.idPoliza));
            }else{
                setTimeout(function(){
                    window.location.reload(true);
                }, 2000);
            }
        }).catch((error)=>{
            localStorage.setItem('conf_FacturaBienServicioFel', 'F');
            NotificationManager.error(
                error.msj,
                'Error',
                4000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        localStorage.setItem('conf_FacturaBienServicioFel', 'F');
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleFactura = id => (dispatch) => {
    api.get(`factura_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            error.body.msj,
            'Error',
            6000
        );
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: [] });;
    })
};


export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const limpiar = () => (dispatch) => {
    detalleFac = [];
    dispatch(initializeForm('FormBienesServiciosFEL'));
    dispatch({ type: DETALLE, detalleFac: detalleFac });
};

export const leerEstablecimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/sucursal/${id}`).then((response) => {
        dispatch({type: ESTABLECIMIENTO, estable: response });
        
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar el establecimiento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const listarEstablecimientos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    return api.get('/sucursal', params).then((data)=>{
        const estable = [];
        data.results.forEach(item=>{
            estable.push({
                value: item.id,
                label: item.codigo + ' - ' + item.nombre_comercial
            })
        })
        if (estable.length === 1 ){
            estable.forEach(item => {
                dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: item.value });
            })
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: data.results[0] });
        }else{
            dispatch({ type: LISTADO_ESTABLECIMIENTOS, listaEs: estable });
        }
        
    }).catch(error=>{
        
        NotificationManager.error(
            'Ocurrió un error al listar establecimientos',
            'Error',
            0
        );
    })
}

export const listarTiposDocu = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/tiposFac', params).then((response) => {
        const tiposD = [];
        response.results.forEach(item => {
            if (item.tipo_documento === 'CARGO'){
                tiposD.push({
                    value: item.id,
                    label: item.tipo + ' - ' + item.descripcion,
                    search: item.categoria
                })
            }
        })
        dispatch({ type: LISTADO_TIPOS_DOCU, tipos: tiposD });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar tipos de documento',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

let detalleFac = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormBienesServiciosFEL.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);

    if (fecha >= fechaI && fecha <= fechaF) {

        if (data.descripcion === undefined || data.descripcion === null) {
            Swal.fire({
                title: 'Factura',
                text: 'Debe de ingresar descripcion',
                type: 'error',
            })
        } else {
            if (data.cantidad === undefined || data.cantidad === 0.000000) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar cantidad',
                    type: 'error',
                })
            } else {
                if (data.precio === undefined || data.precio === 0.00) {
                    Swal.fire({
                        title: 'Factura',
                        text: 'Debe de ingresar precio venta',
                        type: 'error',
                    })
                } else {
                    detalleFac.push({
                        id: data.cantidad+'#'+data.descripcion+'#'+data.precio,
                        desc: data.descripcion,
                        cantidad: data.cantidad,
                        precio: data.precio,
                        tipo: data.tipo_linea,
                        total: parseFloat(parseFloat(data.cantidad) * parseFloat(data.precio)).toFixed(2)
                    })
                    data.cantidad = 0;
                    data.descripcion = '';
                    data.precio = 0.00;
                    data.detalleFac = detalleFac;
                    NotificationManager.success(
                        'Descripción ingresada correctamente',
                        'Exito',
                        3000
                    );
                    dispatch(actualizar());
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Factura',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormBienesServiciosFEL.values;
    dispatch(initializeForm('FormBienesServiciosFEL', data));
    dispatch({ type: DETALLE, detalleFac: data.detalleFac })
};

const eliminarLinea = (id) => (dispatch) => {
    let datos = id.split('#');
    detalleFac.forEach((element, index) => {
        if (element.cantidad === datos[0] && element.desc === datos[1] && element.precio === datos[2]) {
            detalleFac.splice(index, 1);
        }
    })
    dispatch(actualizar());
};

const listarPaises = () => (dispatch) => {
    return api.get("/paises").then(response=>{
        const pais = [];
        response.results.forEach(item=>{
            pais.push({
                value: item.id,
                label: item.pais
            })
        })
        dispatch({ type: LISTADO_PAISES, listaP: pais });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar paises',
            'Error',
            2000
        );
    })
}

export const listarClientes = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cliente/empresa_clientes',params).then(data=>{
        const list_cliente = [];
        if(data){
            data.forEach(item=>{
                list_cliente.push({
                    value: item.id,
                    label: item.identificador + ' ' + item.nombre
                })
            })
        }
        return list_cliente ;
    }).catch((error)=>{
        
        return [];
    })
}

export const tipoCambio = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormBienesServiciosFEL.values;
    if (data === undefined) {
        Swal.fire({
            title: 'Factura',
            text: 'Debe de ingresar datos de la factura',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro)
        if (fecha >= fechaI && fecha <= fechaF) {
            if (data.fechaRegistro === undefined) {
                Swal.fire({
                    title: 'Factura',
                    text: 'Debe de ingresar fecha de la factura',
                    type: 'error',
                })
            } else {
                let fecha = data.fechaRegistro;
                let ruta = window.location.href;
                let id_emp = ruta.split('/');
                let idEmpre = id_emp[5];
                const params = { fecha, idEmpre }
                dispatch(setLoaderC(true));
                return api.get('/tipo_cambio/tipo_cambio_fecha/', params).then((response) => {
                    if (parseFloat(response) === 0) {
                        Swal.fire({
                            title: 'No hay tipo de cambio del dia' + ' ' + moment(fecha).format("DD/MM/YYYY"),
                            html:
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                            type: 'info',
                            background: "black",
                            showCancelButton: true,
                            confirmButtonText: 'Si',
                            cancelButtonText: 'No',
                            confirmButtonColor: "#4040ff",
                            cancelButtonColor: "#646464",
                            reverseButtons: true,
                            customClass: {
                                title: 'sweet_titleImportant',
                            },
                        }).then((result) => {
                            if (result.value) {
                                Swal.fire({
                                    title: 'Ingrese tipo de cambio',
                                    html:
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">' + moment(fecha).format("DD/MM/YYYY") + '</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                    type: 'info',
                                    background: "black",
                                    showCancelButton: true,
                                    confirmButtonText: 'Si',
                                    cancelButtonText: 'No',
                                    confirmButtonColor: "#4040ff",
                                    cancelButtonColor: "#646464",
                                    reverseButtons: true,
                                    customClass: {
                                        title: 'sweet_titleImportant',
                                    },
                                }).then((result) => {
                                    if (result.value) {
                                        let tcambio = document.getElementById('cambio').value
                                        const formData = {
                                            fechaT: fecha,
                                            tipoCambio: tcambio,
                                            empresa: id_emp[5],
                                            tipo: 'manual'
                                        }
                                        api.post('/tipo_cambio/', formData).then(response => {
                                            NotificationManager.success(
                                                'Tipo de cambio guardado',
                                                'Exito',
                                                4000
                                            );

                                        }).catch((error) => {
                                            NotificationManager.error(
                                                error.msj,
                                                'Error',
                                                0
                                            );
                                        })
                                    }
                                });
                            }
                        });
                    } else {
                        return response;
                    }
                }).catch((error) => {
                    NotificationManager.error(
                        'Ocurrió un error al consultar tipo de cambio',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoaderC(false));
                });
            }
        } else {
            Swal.fire({
                title: 'Factura',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })
        }
    }
}


export const listarMonedas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    api.get('/monedas/moneda_empresa', params).then((response) => {
        const moneda = [];
        response.forEach(item => {
            moneda.push({
                value: item.id,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        const monedaNuevo = [];
        response.forEach(item => {
            monedaNuevo.push({
                value: item.id + '#' + item.simbolo,
                label: item.moneda + ' - ' + item.simbolo
            })
        })
        dispatch({ type: LISTADO_MONEDAS, monedas: moneda });
        dispatch({ type: LISTADO_MONEDAS_NUEVO, monedasNuevo: monedaNuevo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar monedas',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCliente = (datos) => (dispatch, getStore) => {
    const data = getStore().form.FormBienesServiciosFEL.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (datos.validar_nit === 'S' && data.tipo_cliente === 'L'){
        if (data.identificador === 'CF'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else{
            let valid = validar.valNit(data.identificador);
            if (valid === false){
                Swal.fire({
                    title: 'Cliente',
                    text: 'Ingrese Nit válido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else{
        if (datos.validar_dpi === 'S' && data.tipo_cliente === 'P'){
            let valid = validar.valCui(data.identificador);
            if (valid[0].validar === false){
                Swal.fire({
                    title: 'Factura',
                    text: 'DPI con formato inválido',
                    type: 'error',
                })
            }else{
                const formData = {
                    identificador: data.identificador,
                    nombre: data.nombre,
                    direccion: data.direccion,
                    telefono: data.telefono,
                    correo: data.correo,
                    limite: data.limite_credito,
                    tipo_cliente: data.tipo_cliente,
                    dias_credito: data.dias_credito,
                    pais: data.pais,
                    modulo: 'FAC',
                    empresa: id_emp[5]
                }
                dispatch(setLoader(true));
                api.post('/cliente/', formData).then(() => {
                    NotificationManager.success(
                        'Cliente creado',
                        'Exito',
                        3000
                    );
                }).catch((error) => {
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
        if (datos.validar_nit === 'S' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
        if (datos.validar_nit === 'N' && data.tipo_cliente === 'E'){
            const formData = {
                identificador: data.identificador,
                nombre: data.nombre,
                direccion: data.direccion,
                telefono: data.telefono,
                correo: data.correo,
                limite: data.limite_credito,
                tipo_cliente: data.tipo_cliente,
                dias_credito: data.dias_credito,
                pais: data.pais,
                modulo: 'FAC',
                empresa: id_emp[5]
            }
            dispatch(setLoader(true));
            api.post('/cliente/', formData).then(() => {
                NotificationManager.success(
                    'Cliente creado',
                    'Exito',
                    3000
                );
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/centrocosto/empresa_centro_costo/', params).then(data => {
        const list_cc = [];
        if (data) {
            data.forEach(item => {
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error) => {
        
        return [];
    })
}

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.BienesServiciosFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo = response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}


export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarImpuestos = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5]
    const params = { id };
    dispatch(setLoader(true));
    api.get('/impuesto/impuesto_empresa', params).then((response) => {
        dispatch({ type: LISTADO_IMPUESTO, impuesto: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar impuesto',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        localStorage.removeItem('id_poliza_bien_servicio_factura');
        localStorage.removeItem('dif');
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            let ruta = `/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

//Para formulario facturacion bienes y servicios
export const registroPolizaDetalleSCCServicio = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.BienesServiciosFormTabs.values;
    const poliza = localStorage.getItem('id_poliza_bien_servicio_factura');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[5],
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('id_poliza_bien_servicio_factura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('BienesServiciosFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesServiciosFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesServiciosFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('id_poliza_bien_servicio_factura')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('BienesServiciosFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesServiciosFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('BienesServiciosFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLineaPoliza = id => (dispatch) => {
    const poliza = localStorage.getItem('id_poliza_bien_servicio_factura');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            3000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    leer,
    getModulo,
    leerPeriodoD,
    registroFactura,
    parametrosEmpresa,
    limpiar,
    leerEstablecimiento,
    listarEstablecimientos,
    listarTiposDocu,
    agregarLinea,
    actualizar,
    eliminarLinea,
    listarPaises,
    listarClientes,
    tipoCambio,
    listarMonedas,
    registroCliente,
    listarCuentas,
    listarCC,
    registroCuenta,
    listarNiveles,
    listarImpuestos,
    leerDetalle,
    confirmarPoliza,
    registroPolizaDetalleSCCServicio,
    eliminarLineaPoliza
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_FACTURA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [CLIENTE]: (state, { cli }) => {
        return {
            ...state,
            cli,
        };
    },
    [DETALLE]: (state, { detalleFac }) => {
        return {
            ...state,
            detalleFac,
        };
    },
    [ESTABLECIMIENTO]: (state, { estable }) => {
        return {
            ...state,
            estable,
        };
    },
    [LISTADO_ESTABLECIMIENTOS]: (state, { listaEs }) => {
        return {
            ...state,
            listaEs,
        };
    },
    [LISTADO_TIPOS_DOCU]: (state, { tipos }) => {
        return {
            ...state,
            tipos,
        };
    },
    [GUARDAR_FACTURA]: (state, { lecturaFac }) => {
        return {
            ...state,
            lecturaFac,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [DETALLE]: (state, { detalleFac }) => {
        return {
            ...state,
            detalleFac,
        };
    },
    [LISTADO_PAISES]: (state, { listaP }) => {
        return {
            ...state,
            listaP,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [LISTADO_MONEDAS]: (state, { monedas }) => {
        return {
            ...state,
            monedas,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LISTADO_IMPUESTO]: (state, { impuesto }) => {
        return {
            ...state,
            impuesto,
        };
    },
    [DETALLE_PRODUCTOS_SERVICIO]: (state, { detalleProductoServicio }) => {
        return {
            ...state,
            detalleProductoServicio,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LISTADO_MONEDAS_NUEVO]: (state, { monedasNuevo }) => {
        return {
            ...state,
            monedasNuevo,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    }
};

export const initialState = {
    loader: false,
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    modulo: [],
    periodoD: [],
    cli: [],
    detalleProducto: [],
    detalleEnv: [],
    estable: [],
    listaEs: [],
    tipos: [],
    datos: [],
    lecturaFac: [],
    pro: [],
    loaderC: false,
    listaP: [],
    monedas: [],
    detalleFac: [],
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    nivel: [],
    impuesto: [],
    detalleProductoServicio: [],
    detalle: [],
    monedasNuevo: []
};

export default handleActions(reducers, initialState)

    