import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_CHEQUES = 'LISTADO_CHEQUES';
const GUARDAR_CHEQUE = 'GUARDAR_CHEQUE';
const LISTADO_DOCUMENTOS = 'LISTADO_DOCUMENTOS';
const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const DETALLE_CHEQUE = 'DETALLE_CHEQUE';
const DETALLE_DOCUMENTOS = 'DETALLE_DOCUEMENTOS';
const CUENTA_BANCARIA = 'CUENTA_BANCARIA';
const LOADER = 'LOADER';
const PERIODO_D = 'PERIODOD';
const MODULO = 'MODULO';
const NIVEL = 'NIVEL';
const LOADERC = 'LOADERC';
const PARAMETRO = 'PARAMETRO';
const DETALLE = 'DETALLE';
const DOCUMENTOS = 'DOCUMENTOS';
const LISTADO_CUENTAS = 'LISTADO_CUENTAS';
const CAMBIO = 'CAMBIO';
const ID_MONEDA = 'ID_MONEDA';
const PROVEEDOR = 'PROVEEDOR';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    let tipo = 'CK';
    //const params = { page, id, id_period, tipo };
    const params = { id, id_period, tipo };
    dispatch(setLoader(true));
    api.get('/egresos', params).then((response) => {
        dispatch({ type: LISTADO_CHEQUES, data: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar cheques',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerCuentaB = (cuenta_bancaria) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    api.get(`/cuenta_banco/${cuenta_bancaria}`).then((response) => {
        dispatch({ type: CUENTA_BANCARIA, cuentaBan: response });
        let cuenta = cuenta_bancaria;
        let params = {id, cuenta}
        api.get('/egresos/numero_cheque/', params).then((response) => {
            localStorage.setItem('ck',parseInt(response.detalle)+1)
            localStorage.setItem('numeroCheque',parseInt(response.detalle)+1)
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al consultar correlativo',
                'Error',
                2000
            );
        })
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar Cuenta Bancaria',
            'Error',
            2000
        );
    })
}

export const registroCheque = (periodoD, cuentaBan, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCheques.values;

    let tipoCambio = localStorage.getItem('TC_CK');
    let concepto = localStorage.getItem('concepto');
    let saldo = parseFloat(localStorage.getItem('totalDoc'));
    
    let total = parseFloat(data.total);
    let abono =  parseFloat(data.abono);
    let prov = data.proveedor_documento;
    if (prov === undefined){
        prov = data.proveedor;
    }else{
        prov = data.proveedor_documento.value;
    }
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let poliza = '';
    
    if (fecha >= fechaI && fecha <= fechaF) {
        if (concepto === 'PAGO A PROVEEDORES'){
            if (abono > total){
                Swal.fire({
                    title: 'Cheques',
                    text: 'El abono es mayor al total del cheque',
                    type: 'error',
                })        

            }else{
                if (abono > saldo){
                    Swal.fire({
                        title: 'Cheques',
                        text: 'El abono es mayor al saldo del documento',
                        type: 'error',
                    })    
                }else{
                    if (datos.contabilidad === 'S') {
                        poliza = 'banco';
                    }
                    if (datos.contabilidad === 'N') {
                        poliza = '';
                    }
                    if (cuentaBan.cambio === 'S' && tipoCambio === null){
                        localStorage.setItem('ck_ban', 'F')
                        Swal.fire({
                            title: 'Cheques',
                            text: 'Tiene que ingresar tipo de cambio',
                            type: 'error',
                        })
                    }else{
                        const formData = {
                            fechaRegistro: data.fechaRegistro,
                            numero_cheque: localStorage.getItem('numeroCheque'),
                            total: data.total,
                            cuenta: id_emp[9],
                            anulado: data.anulado,
                            concepto: localStorage.getItem('concepto'),
                            descripcion: data.descripcion,
                            nombre: localStorage.getItem('ProveedorCK').trimStart(),
                            periodo: id_emp[7],
                            tipoDoc: localStorage.getItem('tipoDocBan'),
                            negociable: data.negociable,
                            idDoc: localStorage.getItem('documentoEgreso'),
                            abono: parseFloat(data.abono),
                            saldo: parseFloat(localStorage.getItem('totalDoc'))-parseFloat(data.abono),
                            modulo: 'BAN',
                            empresa: id_emp[5],
                            poliza: poliza,
                            proveedor: prov,
                            tipo: 'CK',
                            detalleDoc: data.detalleD,
                            tipo_cambio: localStorage.getItem('TC_CK'),
                            cambio: cuentaBan.cambio,
                            idPoliza: localStorage.getItem('idPolizaCheque')
                        }
                        dispatch(setLoader(true));
                        api.post('/egresos/', formData).then((response) => {
                            localStorage.setItem('ck_ban', 'T')
                            response.fechaRegistro = response.documento.fecha_registro;
                            response.fecha = response.documento.fecha;
                            response.numero_cheque = response.documento.numero_cheque;
                            response.descripcion = response.documento.descripcion;
                            response.identificador = response.documento.nit_proveedor;
                            response.total = response.documento.total;
                            response.totalAbono = response.totalA;
                            response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
                            if (response.idPoliza){
                                localStorage.setItem('idPolizaCheque', response.idPoliza);
                                const poliza = localStorage.getItem('idPolizaCheque');
                                localStorage.setItem('detalleID_cheque', response.detalle);
                                dispatch(initializeForm('FormCheques', response));
                                dispatch(leerDetalleDocumentos(response.detalle));
                                dispatch(leerDetalle(poliza));
                            }else{
                                Swal.fire({
                                    title: '¿Imprimir cheque?',
                                    text: '¡Desea imprimir cheque!',
                                    type: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: '¡Sí, imprimir!',
                                    cancelButtonText: 'No, cancelar',
                                    reverseButtons: true
                                }).then((result) => {
                                    if (result.value) {
                                        let imp = 'imprimirCk';
                                        let id_ck = response.documento.id;
                                        let id = id_emp[5];
                                        const params = { id, imp, id_ck };
                                        dispatch(setLoader(true));
                                        api.getPdf('/egresos', params).then((response)=>{
                                            localStorage.removeItem('idPolizaCheque');
                                            localStorage.removeItem('detalleID_cheque');
                                            let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                            dispatch(push(ruta));
                                            if (response.headers['content-type'] == 'application/pdf'){
                                                var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                                var link = document.createElement('a');
                                                link.href = window.URL.createObjectURL(blob);
                                                link.download = 'cheque.pdf';
                                                window.open(link, '_blank');
                                                return false;
                                            }
                                            
                                        }).catch((error)=>{
                                            
                                            NotificationManager.error(
                                                'Ocurrió un error al generar reporte',
                                                'Error',
                                                2000
                                            );
                                        }).finally(() => {
                                            dispatch(setLoader(false));
                                        });
                                    }else {
                                        localStorage.removeItem('idPolizaCheque');
                                        localStorage.removeItem('detalleID_cheque');
                                        window.location.reload(true);
                                    }
                                });
                            }
                    
                            NotificationManager.success(
                                'Documento agregado correctamente',
                                'Exito',
                                3000
                            );
                    
                        }).catch((error) => {
                            localStorage.setItem('ck_ban', 'F')
                            NotificationManager.error(
                                error.msj,
                                'Error',
                                2000
                            );
                        }).finally(() => {
                            dispatch(setLoader(false));
                        });
                    }
                }
            }
        }
        if (concepto === 'ANTICIPO A PROVEEDORES' ){
            if (total === undefined){
                Swal.fire({
                    title: 'Cheques',
                    text: 'Tiene que ingresar total del cheque',
                    type: 'error',
                })    
            }else{
                if (datos.contabilidad === 'S') {
                    poliza = 'bancoAnticipo';
                }
                if (datos.contabilidad === 'N') {
                    poliza = '';
                }
                if (cuentaBan.cambio === 'S' && tipoCambio === null){
                    localStorage.setItem('ck_ban', 'F')
                    Swal.fire({
                        title: 'Cheques',
                        text: 'Tiene que ingresar tipo de cambio',
                        type: 'error',
                    })
                }else{
                    const formData = {
                        fechaRegistro: data.fechaRegistro,
                        numero_cheque: localStorage.getItem('numeroCheque'),
                        total: data.total,
                        cuenta: id_emp[9],
                        anulado: data.anulado,
                        concepto: localStorage.getItem('concepto'),
                        descripcion: data.descripcion,
                        nombre: localStorage.getItem('ProveedorCK').trimStart(),
                        negociable: data.negociable,
                        periodo: id_emp[7],
                        modulo: 'BAN',
                        empresa: id_emp[5],
                        poliza: poliza,
                        proveedor: data.proveedor_documento.value,
                        tipo: 'CK',
                        tipo_cambio: localStorage.getItem('TC_CK'),
                        cambio: cuentaBan.cambio,
                        idPoliza: localStorage.getItem('idPolizaCheque')
                    }
                    dispatch(setLoader(true));
                    api.post('/egresos/', formData).then((response) => {
                        localStorage.setItem('ck_ban', 'T')
                        response.fechaRegistro = response.documento.fecha_registro;
                        response.fecha = response.documento.fecha;
                        response.numero_cheque = response.documento.numero_cheque;
                        response.descripcion = response.documento.descripcion;
                        response.identificador = response.documento.nit_proveedor;
                        response.total = response.documento.total;
                        response.totalAbono = response.totalA;
                        response.nombre = response.documento.nombre;
                        response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
                        if (response.idPoliza){
                            localStorage.setItem('idPolizaCheque', response.idPoliza);
                            const poliza = localStorage.getItem('idPolizaCheque');
                            localStorage.setItem('detalleID_cheque', response.detalle);
                            dispatch(initializeForm('FormCheques', response));
                            dispatch(leerDetalleDocumentos(response.detalle));
                            dispatch(leerDetalle(poliza));
                        }else{
                            Swal.fire({
                                title: '¿Imprimir cheque?',
                                text: '¡Desea imprimir cheque!',
                                type: 'question',
                                showCancelButton: true,
                                confirmButtonText: '¡Sí, imprimir!',
                                cancelButtonText: 'No, cancelar',
                                reverseButtons: true
                            }).then((result) => {
                                if (result.value) {
                                    let imp = 'imprimirCk';
                                    let id_ck = response.documento.id;
                                    let id = id_emp[5];
                                    const params = { id, imp, id_ck };
                                    dispatch(setLoader(true));
                                    api.getPdf('/egresos', params).then((response)=>{
                                        localStorage.removeItem('idPolizaCheque');
                                        localStorage.removeItem('detalleID_cheque');
                                        let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                        dispatch(push(ruta));
                                        if (response.headers['content-type'] == 'application/pdf'){
                                            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                            var link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(blob);
                                            link.download = 'cheque.pdf';
                                            window.open(link, '_blank');
                                            return false;
                                        }
                                        
                                    }).catch((error)=>{
                                        
                                        NotificationManager.error(
                                            'Ocurrió un error al generar reporte',
                                            'Error',
                                            2000
                                        );
                                    }).finally(() => {
                                        dispatch(setLoader(false));
                                    });
                                }else {
                                    localStorage.removeItem('idPolizaCheque');
                                    localStorage.removeItem('detalleID_cheque');
                                    window.location.reload(true);
                                }
                            });
                        }
            
                        NotificationManager.success(
                            'Cheque creado correctamente',
                            'Exito',
                            3000
                        );
                
                    }).catch((error) => {
                        localStorage.setItem('ck_ban', 'F')
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
        if (concepto === 'PAGOS VARIOS'){
            if (total === undefined){
                Swal.fire({
                    title: 'Cheques',
                    text: 'Tiene que ingresar total del cheque',
                    type: 'error',
                })    
            }else{
                if (datos.contabilidad === 'S') {
                    poliza = 'bancoPagosVarios';
                }
                if (datos.contabilidad === 'N') {
                    poliza = '';
                }
                if (cuentaBan.cambio === 'S' && tipoCambio === null){
                    localStorage.setItem('ck_ban', 'F')
                    Swal.fire({
                        title: 'Cheques',
                        text: 'Tiene que ingresar tipo de cambio',
                        type: 'error',
                    })
                }else{
                    const formData = {
                        fechaRegistro: data.fechaRegistro,
                        numero_cheque: localStorage.getItem('numeroCheque'),
                        total: data.total,
                        cuenta: id_emp[9],
                        anulado: data.anulado,
                        concepto: localStorage.getItem('concepto'),
                        descripcion: data.descripcion,
                        nombre: localStorage.getItem('ProveedorCK').trimStart(),
                        negociable: data.negociable,
                        periodo: id_emp[7],
                        modulo: 'BAN',
                        empresa: id_emp[5],
                        poliza: poliza, 
                        tipo: 'CK',
                        tipo_cambio: localStorage.getItem('TC_CK'),
                        cambio: cuentaBan.cambio,
                        idPoliza: localStorage.getItem('idPolizaCheque')
                    }
                    dispatch(setLoader(true));
                    api.post('/egresos/', formData).then((response) => {
                        localStorage.setItem('ck_ban', 'T')
                        response.fechaRegistro = response.documento.fecha_registro;
                        response.fecha = response.documento.fecha;
                        response.numero_cheque = response.documento.numero_cheque;
                        response.descripcion = response.documento.descripcion;
                        response.identificador = response.documento.nit_proveedor;
                        response.total = response.documento.total;
                        response.totalAbono = response.totalA;
                        response.nombre = response.documento.nombre;
                        response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
                        if (response.idPoliza){
                            localStorage.setItem('idPolizaCheque', response.idPoliza);
                            const poliza = localStorage.getItem('idPolizaCheque');
                            dispatch(leerDetalle(poliza));
                            localStorage.setItem('detalleID_cheque', response.detalle);
                            dispatch(initializeForm('FormCheques', response));
                            dispatch(leerDetalleDocumentos(response.detalle));
                        }else{
                            Swal.fire({
                                title: '¿Imprimir cheque?',
                                text: '¡Desea imprimir cheque!',
                                type: 'question',
                                showCancelButton: true,
                                confirmButtonText: '¡Sí, imprimir!',
                                cancelButtonText: 'No, cancelar',
                                reverseButtons: true
                            }).then((result) => {
                                if (result.value) {
                                    let imp = 'imprimirCk';
                                    let id_ck = response.documento.id;
                                    let id = id_emp[5];
                                    const params = { id, imp, id_ck };
                                    dispatch(setLoader(true));
                                    api.getPdf('/egresos', params).then((response)=>{
                                        localStorage.removeItem('idPolizaCheque');
                                        localStorage.removeItem('detalleID_cheque');
                                        let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                        dispatch(push(ruta));
                                        if (response.headers['content-type'] == 'application/pdf'){
                                            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                            var link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(blob);
                                            link.download = 'cheque.pdf';
                                            window.open(link, '_blank');
                                            return false;
                                        }
                                        
                                    }).catch((error)=>{
                                        
                                        NotificationManager.error(
                                            'Ocurrió un error al generar reporte',
                                            'Error',
                                            2000
                                        );
                                    }).finally(() => {
                                        dispatch(setLoader(false));
                                    });
                                }else {
                                    localStorage.removeItem('idPolizaCheque');
                                    localStorage.removeItem('detalleID_cheque');
                                    window.location.reload(true);
                                }
                            });
                        }
            
                        NotificationManager.success(
                            'Cheque creado correctamente',
                            'Exito',
                            3000
                        );
                
                    }).catch((error) => {
                        localStorage.setItem('ck_ban', 'F')
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    }else {
        localStorage.setItem('ck_ban', 'F')
        Swal.fire({
            title: 'Cheque',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarCheque = (periodoD, datos) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCheques.values;

    let concepto = localStorage.getItem('concepto');
    let saldo = parseFloat(localStorage.getItem('totalDoc'));
    const id = data.id;
    let total = parseFloat(data.total);
    let abono =  parseFloat(data.abono);
    let prov = data.proveedor_documento;
    if (prov === undefined){
        prov = data.proveedor;
    }else{
        prov = data.proveedor_documento.value;
    }
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);
    let poliza = '';

    if (fecha >= fechaI && fecha <= fechaF) {
        if (concepto === 'PAGO A PROVEEDORES'){
            if (abono > total){
                Swal.fire({
                    title: 'Cheques',
                    text: 'El abono es mayor al total del cheque',
                    type: 'error',
                })        

            }else{
                if (abono > saldo){
                    Swal.fire({
                        title: 'Cheques',
                        text: 'El abono es mayor al saldo del documento',
                        type: 'error',
                    })    
                }else{
                    if (datos.contabilidad === 'S') {
                        poliza = 'banco';
                    }
                    if (datos.contabilidad === 'N') {
                        poliza = '';
                    }
                    const formData = {
                        id: id,
                        mod: 'modificar',
                        fechaRegistro: data.fechaRegistro,
                        total: data.total,
                        numero_cheque: localStorage.getItem('numeroCheque'),
                        concepto: localStorage.getItem('concepto'),
                        descripcion: data.descripcion,
                        nombre: localStorage.getItem('ProveedorCK') ? localStorage.getItem('ProveedorCK').trimStart() : data.nombre,
                        tipoDoc: localStorage.getItem('tipoDocBan'),
                        negociable: data.negociable,
                        empresa: id_emp[5],
                        idDoc: localStorage.getItem('documentoEgreso'),
                        poliza: poliza,
                        tipo: 'CK',
                        modulo: 'BAN',
                        detalle: localStorage.getItem('detalleID_cheque'),
                        tipo_cambio: localStorage.getItem('TC_CK'),
                        cambio: data.cambio,
                        proveedor: prov,
                        idPoliza: localStorage.getItem('idPolizaCheque')
                    }
                    dispatch(setLoader(true));
                    api.put(`/egresos/${id}/`, formData).then((response) => {
                        localStorage.setItem('ck_ban', 'T')
                        response.fechaRegistro = response.documento.fecha_registro;
                        response.numero_cheque = response.documento.numero_cheque;
                        response.descripcion = response.documento.descripcion;
                        response.codigoProveedor = response.documento.proveedor
                        response.proveedorNombre = response.documento.proveedorN
                        response.total = response.documento.total;
                        response.nombre = response.documento.nombre;
                        response.totalAbono = response.totalA;
                        response.cambio = response.documento.cambio;
                        response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
                        if (response.idPoliza){
                            const poliza = localStorage.getItem('idPolizaCheque');
                            dispatch({type: GUARDAR_CHEQUE, lectura: response });
                            dispatch(initializeForm('FormCheques', response));
                            dispatch(leerDetalleCheque(response.documento.id));
                            dispatch(leerDetalleMod(poliza));
                        }else{
                            Swal.fire({
                                title: '¿Imprimir cheque?',
                                text: '¡Desea imprimir cheque!',
                                type: 'question',
                                showCancelButton: true,
                                confirmButtonText: '¡Sí, imprimir!',
                                cancelButtonText: 'No, cancelar',
                                reverseButtons: true
                            }).then((result) => {
                                if (result.value) {
                                    let imp = 'imprimirCk';
                                    let id_ck = response.documento.id;
                                    let id = id_emp[5];
                                    const params = { id, imp, id_ck };
                                    dispatch(setLoader(true));
                                    api.getPdf('/egresos', params).then((response)=>{
                                        localStorage.removeItem('idPolizaCheque');
                                        localStorage.removeItem('detalleID_cheque');
                                        let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                        dispatch(push(ruta));
                                        if (response.headers['content-type'] == 'application/pdf'){
                                            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                            var link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(blob);
                                            link.download = 'cheque.pdf';
                                            window.open(link, '_blank');
                                            return false;
                                        }
                                        
                                    }).catch((error)=>{
                                        
                                        NotificationManager.error(
                                            'Ocurrió un error al generar reporte',
                                            'Error',
                                            2000
                                        );
                                    }).finally(() => {
                                        dispatch(setLoader(false));
                                    });
                                }else {
                                    localStorage.removeItem('idPolizaCheque');
                                    localStorage.removeItem('detalleID_cheque');
                                    let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                    dispatch(push(ruta));
                                }
                            });
                        }
                        
                        NotificationManager.success(
                            'Documento agregado correctamente',
                            'Exito',
                            3000
                        );
                    
                    }).catch((error) => {
                        localStorage.setItem('ck_ban', 'F')
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
        if (concepto === 'ANTICIPO A PROVEEDORES'){
            if (total === undefined){
                Swal.fire({
                    title: 'Cheques',
                    text: 'Tiene que ingresar total del cheque',
                    type: 'error',
                })    
            }else{
                if (datos.contabilidad === 'S') {
                    poliza = 'bancoAnticipo';
                }
                if (datos.contabilidad === 'N') {
                    poliza = '';
                }
                const formData = {
                    id: id,
                    fechaRegistro: data.fechaRegistro,
                    numero_cheque: localStorage.getItem('numeroCheque'),
                    total: data.total,
                    anulado: data.anulado,
                    concepto: localStorage.getItem('concepto'),
                    descripcion: data.descripcion,
                    nombre: localStorage.getItem('ProveedorCK') ? localStorage.getItem('ProveedorCK').trimStart() : data.nombre,
                    negociable: data.negociable,
                    periodo: id_emp[7],
                    modulo: 'BAN',
                    proveedor: prov,
                    empresa: id_emp[5],
                    poliza: poliza,
                    tipo_cambio: localStorage.getItem('TC_CK'),
                    cambio: data.cambio,
                    proveedor: prov,
                    tipo: 'CK',
                    detalle: localStorage.getItem('detalleID_cheque'),
                    idPoliza: localStorage.getItem('idPolizaCheque')
                }
                dispatch(setLoader(true));
                api.put(`/egresos/${id}/`, formData).then((response) => {
                    
                    localStorage.setItem('ck_ban', 'T')
                    response.fechaRegistro = response.documento.fecha_registro;
                    response.numero_cheque = response.documento.numero_cheque;
                    response.descripcion = response.documento.descripcion;
                    response.identificador = response.documento.nit_proveedor;
                    response.total = response.documento.total;
                    response.totalAbono = response.totalA;
                    response.cambio = response.documento.cambio;
                    response.nombre = response.documento.nombre;
                    response.codigoProveedor = response.documento.proveedor
                    response.proveedorNombre = response.documento.proveedorN
                    response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
                    if (response.idPoliza){
                        const poliza = localStorage.getItem('idPolizaCheque');
                        dispatch({type: GUARDAR_CHEQUE, lectura: response });
                        dispatch(initializeForm('FormCheques', response));
                        dispatch(leerDetalleMod(poliza));
                    }else{
                        Swal.fire({
                            title: '¿Imprimir cheque?',
                            text: '¡Desea imprimir cheque!',
                            type: 'question',
                            showCancelButton: true,
                            confirmButtonText: '¡Sí, imprimir!',
                            cancelButtonText: 'No, cancelar',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                let imp = 'imprimirCk';
                                let id_ck = response.documento.id;
                                let id = id_emp[5];
                                const params = { id, imp, id_ck };
                                dispatch(setLoader(true));
                                api.getPdf('/egresos', params).then((response)=>{
                                    localStorage.removeItem('idPolizaCheque');
                                    localStorage.removeItem('detalleID_cheque');
                                    let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                    dispatch(push(ruta));
                                    if (response.headers['content-type'] == 'application/pdf'){
                                        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                        var link = document.createElement('a');
                                        link.href = window.URL.createObjectURL(blob);
                                        link.download = 'cheque.pdf';
                                        window.open(link, '_blank');
                                        return false;
                                    }
                                    
                                }).catch((error)=>{
                                    
                                    NotificationManager.error(
                                        'Ocurrió un error al generar reporte',
                                        'Error',
                                        2000
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }else {
                                localStorage.removeItem('idPolizaCheque');
                                localStorage.removeItem('detalleID_cheque');
                                let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                dispatch(push(ruta));
                            }
                        });
                    }
                    
                    NotificationManager.success(
                        'Cheque modificado correctamente',
                        'Exito',
                        3000
                    );
                
                }).catch((error) => {
                    localStorage.setItem('ck_ban', 'F')
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
        if (concepto === 'PAGOS VARIOS'){
            if (total === undefined){
                Swal.fire({
                    title: 'Cheques',
                    text: 'Tiene que ingresar total del cheque',
                    type: 'error',
                })    
            }else{
                if (datos.contabilidad === 'S') {
                    poliza = 'bancoPagosVarios';
                }
                if (datos.contabilidad === 'N') {
                    poliza = '';
                }
                const formData = {
                    id: id,
                    fechaRegistro: data.fechaRegistro,
                    numero_cheque: localStorage.getItem('numeroCheque'),
                    total: data.total,
                    anulado: data.anulado,
                    concepto: localStorage.getItem('concepto'),
                    descripcion: data.descripcion,
                    nombre: localStorage.getItem('ProveedorCK') ? localStorage.getItem('ProveedorCK').trimStart() : data.nombre,
                    negociable: data.negociable,
                    periodo: id_emp[7],
                    modulo: 'BAN',
                    proveedor: prov,
                    empresa: id_emp[5],
                    poliza: poliza,
                    tipo_cambio: localStorage.getItem('TC_CK'),
                    cambio: data.cambio,
                    tipo: 'CK',
                    detalle: localStorage.getItem('detalleID_cheque'),
                    idPoliza: localStorage.getItem('idPolizaCheque')
                }
                dispatch(setLoader(true));
                api.put(`/egresos/${id}/`, formData).then((response) => {
                    
                    localStorage.setItem('ck_ban', 'T')
                    response.fechaRegistro = response.documento.fecha_registro;
                    response.numero_cheque = response.documento.numero_cheque;
                    response.descripcion = response.documento.descripcion;
                    response.identificador = response.documento.nit_proveedor;
                    response.total = response.documento.total;
                    response.totalAbono = response.totalA;
                    response.cambio = response.documento.cambio;
                    response.nombre = response.documento.nombre;
                    response.codigoProveedor = response.documento.proveedor
                    response.proveedorNombre = response.documento.proveedorN
                    response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
                    if (response.idPoliza){
                        const poliza = localStorage.getItem('idPolizaCheque');
                        dispatch({type: GUARDAR_CHEQUE, lectura: response });
                        dispatch(initializeForm('FormCheques', response));
                        dispatch(leerDetalleMod(poliza));
                    }else{
                        Swal.fire({
                            title: '¿Imprimir cheque?',
                            text: '¡Desea imprimir cheque!',
                            type: 'question',
                            showCancelButton: true,
                            confirmButtonText: '¡Sí, imprimir!',
                            cancelButtonText: 'No, cancelar',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                let imp = 'imprimirCk';
                                let id_ck = response.documento.id;
                                let id = id_emp[5];
                                const params = { id, imp, id_ck };
                                dispatch(setLoader(true));
                                api.getPdf('/egresos', params).then((response)=>{
                                    localStorage.removeItem('idPolizaCheque');
                                    localStorage.removeItem('detalleID_cheque');
                                    let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                    dispatch(push(ruta));
                                    if (response.headers['content-type'] == 'application/pdf'){
                                        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                                        var link = document.createElement('a');
                                        link.href = window.URL.createObjectURL(blob);
                                        link.download = 'cheque.pdf';
                                        window.open(link, '_blank');
                                        return false;
                                    }
                                    
                                }).catch((error)=>{
                                    
                                    NotificationManager.error(
                                        'Ocurrió un error al generar reporte',
                                        'Error',
                                        2000
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }else {
                                localStorage.removeItem('idPolizaCheque');
                                localStorage.removeItem('detalleID_cheque');
                                let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                                dispatch(push(ruta));
                            }
                        });
                    }
                    
                    NotificationManager.success(
                        'Cheque modificado correctamente',
                        'Exito',
                        3000
                    );
                
                }).catch((error) => {
                    localStorage.setItem('ck_ban', 'F')
                    NotificationManager.error(
                        error.msj,
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }else {
        localStorage.setItem('ck_ban', 'F')
        Swal.fire({
            title: 'Cheques',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}


export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/egresos/${id}`).then((response) => {
        
        response.fechaRegistro = response.fecha_registro;
        let neg = false;
        if (response.negociable === 'SI'){
            neg = true;
        }
        
        response.negociable = neg;
        response.codigoProveedor = response.proveedor
        response.proveedorNombre = response.proveedorN
        response.totalAbono = response.total;
        response.total = response.total;
        localStorage.setItem('concepto', response.concepto);
        localStorage.setItem('numeroCheque',response.numero_cheque);
        localStorage.setItem('ck',response.numero_cheque);
        if (response.concepto === 'PAGO A PROVEEDORES'){
            dispatch(leerDocumentos(response.proveedor, response.fechaRegistro, response.cambio, response.idMoneda));
            localStorage.setItem('ProveedorCK', response.nombre); 
            localStorage.setItem('detalleID_cheque', response.id);
        }
        const editar = window.location.href.includes('editar');
        if (editar){
            localStorage.setItem('idPolizaCheque', response.poliza);
        }
        if (response.poliza){
            dispatch(leerDetalle(response.poliza));
        }
        dispatch({type: GUARDAR_CHEQUE, lectura: response });
        dispatch({type: ID_MONEDA, idMoneda: response.idMoneda });
        dispatch({type: CAMBIO, cambio: response.cambio });
        dispatch({type: PROVEEDOR, proveedorD: response.proveedor });
        dispatch(initializeForm('FormCheques', response ));
        dispatch(leerDetalleCheque(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar el cheque',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

const leerDetalleCheque = id => (dispatch) => {
    api.get(`/egresos_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_CHEQUE, detalleCK: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            2000
        );
    })
};


export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/cuentas/empresa_cuentas/', params).then(data => {
        const list_cuentas = [];
        if (data) {
            data.forEach(item => {
                if (item.tipo === 'A') {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                } else {
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error) => {
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/centrocosto/empresa_centro_costo/',params).then(data=>{
        const list_cc = [];
        if(data){
            data.forEach(item=>{
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error)=>{
        return [];
    })
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'reporte';
    const params = {id, search, tipo}
    return api.get('/proveedor/empresa_proveedores',params).then(data=>{
        const list_proveedor = [];
        if(data){
            data.forEach(item=>{
                list_proveedor.push({
                    value: item.id,
                    label: item.identificador + ' - ' + item.nombre
                })
            })
        }
        return list_proveedor;
    }).catch((error)=>{
        return [];
    })
}


export const leerDocumentos = (id, fecha_fin, cambio, moneda) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let fechaF = fecha_fin;
    const params = {id, idEmpre, fechaF, cambio, moneda }
    dispatch(setLoaderC(true));
    api.get('/egresos/documentos_proveedor/',params).then((response) => {
        const saldos = response.detalle.filter((item) => parseFloat(item.total) >= 0.01)
        dispatch({type: LISTADO_DOCUMENTOS, doc: saldos });
        const documento = [];
        response.detalle.forEach(item => {
            if (parseFloat(item.total) >= 0.01) {
                documento.push({
                    value: item.id + ' # ' + item.total + ' # ' + item.tipo + ' # ' + item.fecha + ' # ' + item.serie + ' # ' + item.numero,
                    label: moment(item.fecha).format("DD/MM/YYYY") + ' --- ' + item.serie + ' / ' + item.numero + ' -- ' + ' Saldo: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(item.total),
                })
            }
        })
        dispatch({ type: DOCUMENTOS, list_doc: documento })
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar documentos del proveedor',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarNiveles = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response) => {
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response) => {
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const leerDetalleDocumentos = id => (dispatch) => {
    api.get(`/egresos_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_DOCUMENTOS, detalleDoc: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


const eliminarDocumento = id => (dispatch) => {
    
    const detalle = localStorage.getItem('detalleID_cheque');
    dispatch(setLoader(true));
    api.eliminar(`/egresos_detalle/${id}`).then((response) => {
        if (detalle){
            const poliza = localStorage.getItem('idPolizaCheque');
            response.fechaRegistro = response.documento.fecha_registro;
            response.numero_cheque = response.documento.numero_cheque;
            response.descripcion = response.documento.descripcion;
            response.proveedorNombre = response.documento.proveedorN
            response.nombre = response.documento.nombre;
            response.total = response.documento.total;
            response.diferencia = parseFloat(response.documento.total)-parseFloat(response.totalA)
            response.totalAbono = response.totalA;
            response.poliza = response.documento.poliza;
            response.id = response.documento.id;
            response.proveedor = response.documento.proveedor;  
            dispatch({type: ID_MONEDA, idMoneda: response.documento.idMoneda });
            dispatch({type: CAMBIO, cambio: response.documento.cambio });
            dispatch({type: PROVEEDOR, proveedor: response.documento.proveedor })
            dispatch(leerDetalleCheque(response.documento.id));
            dispatch(leerDetalleMod(poliza));
            dispatch(initializeForm('FormCheques', response));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar el documento',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const confirmarPoliza = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00) {
        NotificationManager.success(
            'Póliza guardada correctamente',
            'Exito',
            3000
        );
        Swal.fire({
            title: '¿Imprimir cheque?',
            text: '¡Desea imprimir cheque!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, imprimir!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let imp = 'imprimirCk';
                let id_ck = '';
                const editar = window.location.href.includes('editar');
                if (editar){
                    id_ck = id_emp[8];
                }else{
                    id_ck = localStorage.getItem('detalleID_cheque');
                }
                const params = { id, imp, id_ck };
                dispatch(setLoader(true));
                api.getPdf('/egresos', params).then((response)=>{
                    localStorage.removeItem('idPolizaCheque');
                    localStorage.removeItem('detalleID_cheque');
                    let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                    dispatch(push(ruta));
                    if (response.headers['content-type'] == 'application/pdf'){
                        var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'cheque.pdf';
                        window.open(link, '_blank');
                        return false;
                    }
                    
                }).catch((error)=>{
                    
                    NotificationManager.error(
                        'Ocurrió un error al generar reporte',
                        'Error',
                        2000
                    );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }else {
                localStorage.removeItem('idPolizaCheque');
                localStorage.removeItem('detalleID_cheque');
                const editar = window.location.href.includes('editar');
                if (editar){
                    let ruta = `/bancos/${id_emp[5]}/cheques/${id_emp[7]}`
                    dispatch(push(ruta));
                }else{
                    window.location.reload(true);
                }
            }
            
        });
    } else {
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(dif)}`,
            type: 'error',
        })
    }
}

export const registroPolizaDetalleSCC = () => (dispatch, getStore) => {
    const data = getStore().form.ChequesFormTabs.values;
    const poliza = localStorage.getItem('idPolizaCheque');
    const formData = {
        empresa: localStorage.getItem('id'),
        periodo: localStorage.getItem('id_periodo'),
        cc: 0,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaCheque')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('FormChequesTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormChequesTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('FormChequesTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaCheque')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ChequesFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ChequesFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ChequesFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const registroPolizaDetalle = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.ChequesFormTabs.values;
    const poliza = localStorage.getItem('idPolizaCheque');
    const formData = {
        empresa: id_emp[5],
        periodo: id_emp[7],
        cc: data.cc.value,
        cuenta: data.cuenta.value,
        debe: data.debe,
        haber: data.haber,
        poliza: poliza
    }
    if (formData.debe === undefined && formData.haber === undefined) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar valor en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores a 0.00 en debe o haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01) {
        Swal.fire({
            title: 'Nueva poliza',
            text: 'No puede llevar valores en debe y haber',
            type: 'error',
        })
    } else if (parseFloat(formData.debe) >= 0.01) {
        formData.haber = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            const poliza = localStorage.getItem('idPolizaCheque')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ChequesFormTabs', response));
                dispatch(leerDetalle(response.detalle.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ChequesFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ChequesFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else if (parseFloat(formData.haber) >= 0.01) {
        formData.debe = 0.00;
        dispatch(setLoader(true));
        api.post('/polizas/encabezado_polizas/', formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            const poliza = localStorage.getItem('idPolizaCheque')
            if (poliza === null) {
                response.debe = 0.00;
                response.haber = 0.00;
                dispatch(initializeForm('ChequesFormTabs', response));
                dispatch(leerDetalle(response.encabezado));
            } else {
                const editar = window.location.href.includes('editar');
                if (editar) {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ChequesFormTabs', response));
                    dispatch(leerDetalleMod(poliza));
                } else {
                    response.debe = 0.00;
                    response.haber = 0.00;
                    dispatch(initializeForm('ChequesFormTabs', response));
                    dispatch(leerDetalle(poliza));
                }
            }

        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPolizaCheque');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar) {
            dispatch(leerDetalleMod(poliza));
        } else {
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            2000
        );
    })
};

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.ChequesFormTabs.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';

    if (f.length === 3) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito;

    }

    if (f.length === 4) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 5) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6) {
        let nivel1 = f[0].length
        digito = cuenta.substr(cont, nivel1);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel2 = f[1].length;
        cont = cont + nivel1;
        digito = cuenta.substr(cont, nivel2);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel3 = f[2].length;
        cont = cont + nivel2;
        digito = cuenta.substr(cont, nivel3);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel4 = f[3].length;
        cont = cont + nivel3;
        digito = cuenta.substr(cont, nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont + nivel4;
        digito = cuenta.substr(cont, nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont + nivel5;
        digito = cuenta.substr(cont, nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPolizaCheque')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza) {
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta.cuenta + ' ' + response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();

        }else{
            NotificationManager.success(
                'Cuenta creada' + ' ' + response.cuenta + ' ' + response.nombre,
                'Exito',
                2000
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            200
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error) => {
        
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const anular = (id, id_ck, cuenta_ban_ck) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let emp = id_emp[5];
    let idCK = id_ck;
    let cuenta = cuenta_ban_ck;
    let numeroCK = id;
    const params = { numeroCK, emp, idCK, cuenta };
    dispatch(setLoader(true));
    api.put('/egresos/anular/', params).then(() => {
        NotificationManager.success(
            'Cheque anulado correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al anular el cheque',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
        api.get('/empresa/empresa_configuracion_poliza', params).then((datos) => {
            if (datos.validar === 'false'){
                Swal.fire({
                    title: 'Cheques',
                    text: datos.msj,
                    type: 'info',
                    allowOutsideClick: false,
                    confirmButtonText: "Continuar",
                }).then((result) => {
                    if (result.value) {
                        let ruta = `/contabilidad/${id_emp[5]}/config`
                        dispatch(push(ruta));
                    }
                });
            }
            
        }).catch((error)=>{
            
            NotificationManager.error(
                'Ocurrió un error al consultar impuesto',
                'Error',
                8000
            );
        })
    }).catch((error)=>{
        
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const imprimirCK = (idCK) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let imp = 'imprimirCk';
    let id_ck = idCK;
    const params = { id, imp, id_ck };
    dispatch(setLoader(true));
    api.getPdf('/egresos', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'cheque.pdf';
            window.open(link, '_blank');
            return false;
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const tipoCambio = (periodoD, dolares) => (dispatch, getStore) => {
    const data = getStore().form.FormCheques.values;
    
    if (data === undefined) {
        Swal.fire({
            title: 'Cheques',
            text: 'Debe de ingresar datos del cheque',
            type: 'error',
        })
    } else {   
        let fechaI = moment(periodoD.fecha_inicio);
        let fechaF = moment(periodoD.fecha_fin);
        let fecha = moment(data.fechaRegistro)
        if (fecha>=fechaI && fecha<=fechaF){
            if (dolares === 'S'){
                if (data.fechaRegistro === undefined) {
                    Swal.fire({
                        title: 'Cheques',
                        text: 'Debe de ingresar fecha del cheque',
                        type: 'error',
                    })
                } else {
                    let fecha = data.fechaRegistro;
                    let ruta = window.location.href;
                    let id_emp = ruta.split('/');
                    let idEmpre = id_emp[5];
                    const params = {fecha, idEmpre }
                    dispatch(setLoaderC(true));
                    return api.get('/tipo_cambio/tipo_cambio_fecha/',params).then((response) => {
                        if (parseFloat(response) === 0){
                            Swal.fire({
                                title: 'No hay tipo de cambio del dia'+' '+moment(fecha).format("DD/MM/YYYY"),
                                html: 
                                '<div class="col-sm-12 mt-4">' +
                                '<h4 style="color: white;">¿Desea ingresarlo?</h4>' +
                                '</div>',
                                type: 'info',
                                background: "black",
                                showCancelButton: true,
                                confirmButtonText: 'Si',
                                cancelButtonText: 'No',
                                confirmButtonColor: "#4040ff",
                                cancelButtonColor: "#646464",
                                reverseButtons: true,
                                customClass: {
                                    title: 'sweet_titleImportant',
                                },
                            }).then((result) => {
                                if (result.value) {
                                    Swal.fire({
                                        title: 'Ingrese tipo de cambio',
                                        html: 
                                        '<div class="col-sm-12 mt-3">' +
                                        '<h4 style="color: white">'+moment(fecha).format("DD/MM/YYYY")+'</h4>' +
                                        '<input type="number" min="1" id="cambio" class="swal2-input form-control">' +
                                        '</div>',
                                        type: 'info',
                                        background: "black",
                                        showCancelButton: true,
                                        confirmButtonText: 'Si',
                                        cancelButtonText: 'No',
                                        confirmButtonColor: "#4040ff",
                                        cancelButtonColor: "#646464",
                                        reverseButtons: true,
                                        customClass: {
                                            title: 'sweet_titleImportant',
                                        },
                                    }).then((result) => {
                                        if (result.value) {
                                            let tcambio = document.getElementById('cambio').value
                                            const formData = {
                                                fechaT: fecha,
                                                tipoCambio: tcambio,
                                                empresa: id_emp[5],
                                                tipo: 'manual'    
                                            }
                                            api.post('/tipo_cambio/', formData).then(response => {
                                                NotificationManager.success(
                                                    'Tipo de cambio guardado',
                                                    'Exito',
                                                    4000
                                                );
                                                
                                            }).catch((error) => {
                                                NotificationManager.error(
                                                    error.msj,
                                                    'Error',
                                                    0
                                                );
                                            })
                                        }
                                    });
                                }
                            });
                        }else{
                            return response;
                        }
                    }).catch((error)=>{
                        NotificationManager.error(
                            'Ocurrió un error al consultar tipo de cambio',
                            'Error',
                            2000
                        );
                    }).finally(() => {
                        dispatch(setLoaderC(false));
                    });
                }    
            }
        }else{
            Swal.fire({
                title: 'Cheques',
                text: 'Fecha fuera del periodo',
                type: 'error',
            })        
        }
    }
}

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormCheques.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);

    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');
        
        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let totAbono = 0.00;
        if (data.total === undefined) {
            totAbono = 0.00
        } else {
            totAbono = parseFloat(data.total);
        }
        //let GtotalAbono = parseFloat(data.totalDetalleAbono)+parseFloat(data.abono);
        let dif = parseFloat(data.diferencia);
        let totDoc = 0.00;

        let pro = data.proveedor_documento;
        if (pro === undefined) {
            pro = data.proveedor;
        } else {
            pro = data.proveedor_documento.value;
        }
        if (totAbono === 0.00) {
            Swal.fire({
                title: 'Cheques',
                text: 'Monto del documento no puede ser 0.00',
                type: 'error',
            })
        } else {
            if (abono > totAbono) {
                Swal.fire({
                    title: 'Cheques',
                    text: 'El abono no puede ser mayor al monto del documento',
                    type: 'error',
                })
            } else {
                if (abono === 0 || data.abono === undefined) {
                    Swal.fire({
                        title: 'Cheques',
                        text: 'El abono no puede ser 0.00',
                        type: 'error',
                    })
                } else {
                    if (abono > saldo) {
                        Swal.fire({
                            title: 'Cheques',
                            text: 'El abono es mayor al saldo del documento',
                            type: 'error',
                        })
                    } else {
                        if (detalle.length > 0) {
                            const dato = detalle.find(item => item.id === documento[0].trim());

                            if (dato) {
                                NotificationManager.error(
                                    'Documento ya fue ingresado',
                                    'Error',
                                    3000
                                );
                            } else {
                                if (abono > dif) {
                                    Swal.fire({
                                        title: 'Cheques',
                                        text: 'El abono es mayor a la diferencia',
                                        type: 'error',
                                    })
                                } else {
                                    detalle.push({
                                        id: documento[0].trim(),
                                        td: documento[2].trim(),
                                        abono: data.abono,
                                        fecha: documento[3].trim(),
                                        serie: documento[4].trim(),
                                        numero: documento[5].trim(),
                                        monto: documento[1].trim(),
                                        saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2)
                                    })
                                    detalle.forEach(item => totDoc += parseFloat(item.abono));
                                    data.totalAbono = parseFloat(totDoc);
                                    data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                                    data.detalleD = detalle;
                                    data.abono = 0;
                                    NotificationManager.success(
                                        'Documento ingresado correctamente',
                                        'Exito',
                                        3000
                                    );
                                    dispatch(actualizar());
                                }
                            }
                        } else {
                            detalle.push({
                                id: documento[0].trim(),
                                td: documento[2].trim(),
                                abono: data.abono,
                                fecha: documento[3].trim(),
                                serie: documento[4].trim(),
                                numero: documento[5].trim(),
                                monto: documento[1].trim(),
                                saldo: parseFloat(documento[1] - parseFloat(data.abono)).toFixed(2)
                            })
                            detalle.forEach(item => totDoc += parseFloat(item.abono));
                            data.totalAbono = parseFloat(totDoc);
                            data.diferencia = parseFloat(parseFloat(totAbono - parseFloat(totDoc))).toFixed(2);
                            data.detalleD = detalle;
                            data.abono = 0;
                            NotificationManager.success(
                                'Documento ingresado correctamente',
                                'Exito',
                                3000
                            );
                            dispatch(actualizar());
                        }

                    }
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Cheques',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormCheques.values;
    dispatch(initializeForm('FormCheques', data));
    dispatch({ type: DETALLE, detalleDocN: data.detalleD });
};

const eliminarLineaAbonoNuevo = (id) => (dispatch, getStore) => {
    const data = getStore().form.FormCheques.values;
    let totAbono = parseFloat(data.monto);
    let totDoc = 0.00;

    detalle.forEach((element, index) => {
        if (element.id === id) {
            detalle.splice(index, 1);
        }
    })
    detalle.forEach(item => totDoc += parseFloat(item.abono));
    data.totalAbono = parseFloat(totDoc);
    data.diferencia = parseFloat(totAbono - parseFloat(totDoc));
    data.detalleD = detalle;
    dispatch(actualizar());
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormCheques'));
    dispatch({ type: DETALLE, detalleDocN: detalle });
};

export const nuevaLineaDocumento = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormCheques.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fechaRegistro);

    if (fecha >= fechaI && fecha <= fechaF) {
        let docu = data.documento.value;
        let documento = docu.split('#');
        
        let saldo = parseFloat(documento[1].trim());
        let abono = parseFloat(data.abono);
        let GtotalAbono = parseFloat(data.totalDetalleAbono) + parseFloat(data.abono);
        let totAbono = parseFloat(data.monto);

        let prov = data.proveedor_documento;
        if (prov === undefined){
            prov = data.proveedor;
        }else{
            prov = data.proveedor_documento.value;
        }
        if (GtotalAbono > totAbono) {
            Swal.fire({
                title: 'Cheques',
                text: 'El abono no puede ser mayor al monto del documento',
                type: 'error',
            })
        } else {
            if (abono === 0 || data.abono === undefined) {
                Swal.fire({
                    title: 'Cheques',
                    text: 'El abono no puede ser 0.00',
                    type: 'error',
                })
            } else {
                if (abono > saldo) {
                    Swal.fire({
                        title: 'Cheques',
                        text: 'El abono es mayor al saldo del documento',
                        type: 'error',
                    })
                } else {
                    const formData = {
                        fecha_registro: data.fechaRegistro,
                        descripcion: data.descripcion,
                        numero_cheque: data.numero_cheque,
                        tipo: 'CK',
                        td: documento[2].trim(),
                        idDoc: documento[0].trim(),
                        abono: parseFloat(data.abono),
                        saldo: parseFloat(documento[1].trim()) - parseFloat(data.abono),
                        empresa: id_emp[5],
                        detalle: data.id,
                        proveedor: prov,
                        total: data.total,
                        cuenta: data.cuenta_bancaria,
                        idPoliza: data.poliza ? data.poliza : ''
                    }
                    
                    dispatch(setLoader(true));
                    api.post('/egresos/', formData).then((response) => {
                        response.fechaRegistro = response.documento.fecha_registro;
                        response.numero = response.documento.numero;
                        response.numero_cheque = response.documento.numero_cheque;
                        response.descripcion = response.documento.descripcion;
                        response.proveedor = response.documento.proveedor;
                        response.nombre = response.documento.nombre;
                        response.proveedorNombre = response.documento.proveedorN
                        response.proveedor = response.documento.proveedor;
                        response.total = response.documento.total;
                        response.totalAbono = response.totalA;
                        response.id = response.documento.id
                        response.diferencia = parseFloat(response.documento.total) - parseFloat(response.totalA)
                        if (response.documento.poliza){
                            const poliza = localStorage.getItem('idPolizaCheque');
                            dispatch(leerDetalleMod(poliza));
                        }
                        dispatch(leerDetalleCheque(response.documento.id));

                        NotificationManager.success(
                            'Documento agregado correctamente',
                            'Exito',
                            3000
                        );
                    }).catch((error) => {
                        NotificationManager.error(
                            error.msj,
                            'Error',
                            0
                        );
                    }).finally(() => {
                        dispatch(setLoader(false));
                    });
                }
            }
        }
    } else {
        Swal.fire({
            title: 'Cheques',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const listarCuentasBancarias = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    let idCuenta = '';
    api.get('/cuenta_banco', params).then((response) => {
        if (response.length === 1){
            response.forEach(item => {
                idCuenta = item.id
            })
            dispatch({ type: LISTADO_CUENTAS, cuenta: idCuenta });
        }else{
            dispatch({ type: LISTADO_CUENTAS, cuenta: '' });
        }
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al listar cuentas bancarias',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    registroCheque,
    modificarCheque,
    listarCuentas,
    listarCC,
    leer,
    leerDocumentos,
    listarNiveles,
    leerDetalle,
    leerDetalleMod,
    leerDetalleDocumentos,
    eliminarDocumento,
    confirmarPoliza,
    registroCuenta,
    reporteCuentas,
    registroPolizaDetalleSCC,
    registroPolizaDetalle,
    leerCuentaB,
    listarProveedores,
    eliminarLinea,
    anular,
    getModulo,
    leerPeriodoD,
    parametrosEmpresa,
    imprimirCK,
    tipoCambio,
    agregarLinea,
    eliminarLineaAbonoNuevo,
    vaciar,
    nuevaLineaDocumento,
    listarCuentasBancarias
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_CHEQUES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_CHEQUE]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [DETALLE_DOCUMENTOS]: (state, { detalleDoc }) => {
        return {
            ...state,
            detalleDoc,
        };
    },
    [LISTADO_DOCUMENTOS]: (state, { doc }) => {
        return {
            ...state,
            doc,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [CUENTA_BANCARIA]: (state, { cuentaBan }) => {
        return {
            ...state,
            cuentaBan,
        };
    },
    [DETALLE_CHEQUE]: (state, { detalleCK }) => {
        return {
            ...state,
            detalleCK,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [DOCUMENTOS]: (state, { list_doc }) => {
        return {
            ...state,
            list_doc,
        };
    },
    [DETALLE]: (state, { detalleDocN }) => {
        return {
            ...state,
            detalleDocN,
        };
    },
    [LISTADO_CUENTAS]: (state, { cuenta }) => {
        return {
            ...state,
            cuenta,
        };
    },
    [CAMBIO]: (state, { cambio }) => {
        return {
            ...state,
            cambio,
        };
    },
    [ID_MONEDA]: (state, { idMoneda }) => {
        return {
            ...state,
            idMoneda,
        };
    },
    [PROVEEDOR]: (state, { proveedorD }) => {
        return {
            ...state,
            proveedorD,
        };
    },
};

export const initialState = {
    loader: false,
    doc: [],
    nivel: [],
    detalle: [],
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    cuentaBan: [],
    periodoD: [],
    detalleDocN: [],
    modulo: [],
    datos: [],
    list_doc: [],
    detalleCK: [],
    loaderC: false,
    data: [],
    periodoS: null,
    page: 1,
    cuenta: [],
    idMoneda: '',
    cambio: '',
    proveedorD: '',
};

export default handleActions(reducers, initialState)