import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../../components/NavbarModulo';
import DetalleInventario from './DetalleInventario';
import DetalleInventarioVer from './DetalleInventarioVer';
import Modal from '../../../components/ModalC'
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import {
    renderField,
    AsyncSelectField,
    renderDayPickerB,
    AsyncSelectFieldIn,
    renderCurrencyFloatTC,
    SelectField,
    renderNumber,
    renderCurrencyFloat,
    renderCurrencyFloatPrecioVenta
} from "../../../Utils/renderField/renderField";

function FacturaBienesForm(props) {
    const {
        crear,
        bloqueo,
        modulo,
        periodoD,
        detalleProducto,
        registroFacturaBienes,
        eliminarLineaProducto,
        datos,
        listarBodegas,
        leerProductos,
        pro,
        agregarLinea,
        detalleCon,
        estable,
        tipos,
        obtenerPaises,
        listarClientes,
        lecturaFac,
        tipoCambio,
        monedas,
        registroCliente
    } = props;

    
    const estilo = {
        overflow: "auto",
        height: "200px",

    };
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar factura' : 'Crear Factura FEL [ Bienes ]';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver Factura FEL [ Bienes ]'
    }
        
    const [editarT, setEditarT] = useState(editar);
    const [tipoD, setTipoD] = useState(0);
    const [iva, setIva] = useState(true);
    const [estadoModal, cambiarEstadoModal] = useState(false);
    const [tipoCambioD, setTipoCambioD] = useState(1.000000);

    const tipoF = [
        { label: "LOCAL", value: "LOC" },
        { label: "EXPORTACION", value: "EXP" },
    ];

    const tiposCL = [
        {
            value: 'L',
            label: 'LOCAL'
        },
        {
            value: 'E',
            label: 'EXTERIOR'
        },
        {
            value: 'P',
            label: 'PERSONA'
        },
    ];

    let autoFocus = true;
    const handleChangeT = (e) => {
        let existe = tipos.find(element => element.value === e.target.value);
        setEditarT(false);
        setTipoD(e.target.value);
        if (existe.search === 'IVA') {
            setIva(true);
        }
        if (existe.search === 'SIVA') {
            setIva(false);
        }

    }
    const handleOnChange = value => {
        leerProductos(value.value);
    };
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    const [tipoM, setTipoM] = useState(0);
    const [tipoFac, setTipoFac] = useState(0);

    const handleChangeM = e => {
        setTipoM(e.target.value)
        localStorage.setItem('fact_bien_TC', e.target.value)
    };

    const handleChangeTipo = e => {
        setTipoFac(e.target.value)
    };
    
    return (
        <div>
            <NavbarModulo
                periodoD = {periodoD}
                modulo = {modulo}
                titulo = {titulo}
            />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        {crear === false && editar == false &&
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <Field
                                        name="codigo"
                                        placeholder="Codigo establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Código establecimiento"
                                    />
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="establecimiento"
                                        placeholder="Nombre establecimiento"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Nombre establecimiento"
                                    />
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="correlativo"
                                        placeholder="Documento interno"
                                        className="form-control"
                                        component={renderField}
                                        disabled={true}
                                        msj="Documento interno"
                                    />
                                </div>
                            </div>
                        }
                        {crear === true &&
                        <div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Codigo establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="codigo"
                                            className="form-control"
                                            value={estable.codigo}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="codigo">
                                            Código establecimiento
                                        </label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-floating">
                                        <input
                                            placeholder="Nombre establecimiento"
                                            type="text"
                                            autocomplete="off"
                                            name="nombre"
                                            className="form-control"
                                            value={estable.nombre_comercial}
                                            disabled={true}
                                        />
                                        <label className='form-label' htmlFor="nombre">
                                            Nombre establecimiento
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <select className="form-select" id="tipo" name="tipo"
                                            value={editarT === true ? lecturaFac.tipo_documento : tipoD} onChange={handleChangeT} autofocus>
                                            <option selected>Seleccionar...</option>
                                            {tipos.map(
                                                tip => <option key={tip.value} value={tip.value}
                                                    >{tip.label}
                                                </option>)
                                            }
                                            </select>
                                        <label className='form-label' htmlFor="mes">Tipo de documento</label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <select className="form-select" id="tipo" name="tipo"
                                            value={tipoFac} onChange={handleChangeTipo}>
                                            <option selected>Seleccionar...</option>
                                            {tipoF.map(
                                                tip => <option key={tip.value} value={tip.value}
                                                >{tip.label}
                                                </option>)
                                            }
                                            </select>
                                        <label className='form-label' htmlFor="mes">Tipo Factura</label>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="fecha"
                                        component={renderDayPickerB}
                                        msj="Fecha"
                                    />
                                </div>  
                                {tipoFac === 'LOC' && 
                                    <div className='col-3'>
                                        <div className="form-floating">
                                            <select className="form-select" id="moneda" name="moneda"
                                                value={tipoM} onChange={handleChangeM} >
                                                <option selected>Seleccionar...</option>
                                                {monedas.map(
                                                    tip => <option key={tip.value} value={tip.value}
                                                    >{tip.label}
                                                    </option>)
                                                }
                                            </select>
                                            <label className='form-label' htmlFor="moneda">Moneda</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='row mb-2'>
                                <div className='col-3'>
                                    <div className="form-floating">
                                        <NumberFormat
                                            className={classNames('form-control')}
                                            decimalScale={6}
                                            fixedDecimalScale
                                            placeholder="Tipo de cambio"
                                            value={tipoCambioD}
                                            thousandSeparator
                                            onValueChange={(values) => {
                                                setTipoCambioD(values.value)
                                                localStorage.setItem('TC_compra', values.value)
                                            }}
                                        />
                                        <label className='form-label' htmlFor="cuenta">
                                            Tipo de cambio
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => {
                                            let tc = tipoCambio(periodoD);
                                            if (tc === undefined){
                                                setTipoCambioD('1.000000');
                                            }else{
                                                tc.then(d => { 
                                                    setTipoCambioD(d);
                                                });
                                            }
                                        }}
                                    >
                                        Buscar tipo de cambio
                                    </button>
                                </div>
                                <div className='col-3'>
                                    <Field
                                        name="ref_exportacion"
                                        disabled={disabled}
                                        placeholder="Referencia exportación"
                                        className="form-control"
                                        component={renderField}
                                        msj="Referencia exportación"
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-9'>
                                    <Field
                                        name="cliente_documento"
                                        placeholder="Cliente..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarClientes}
                                        msj="Cliente"
                                    />
                                </div>
                                <div className='col-3 mt-2'>
                                    <button
                                        className='btn mr-2 mb-3 btn-secondary'
                                        onClick={() => cambiarEstadoModal(!estadoModal)}
                                    >
                                        Crear nuevo cliente
                                    </button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <Field
                                        name="descripcion"
                                        placeholder="Descripcion"
                                        component={renderField}
                                        className="form-control"
                                        msj="Observaciones"
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </div>
                        }
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        {tipos &&
                                            <div className="form-floating">
                                                <Field
                                                    name="tipo_documento"
                                                    options={tipos}
                                                    crear={crear}
                                                    dato={crear === true ? 0 : lecturaFac.tipo_documento}
                                                    component={SelectField}
                                                    className="form-select"
                                                    disabled={disabled}
                                                    msj="Tipo de documento"
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className='col-3'>
                                        <Field
                                            name="fecha"
                                            component={renderDayPickerB}
                                            msj="Fecha"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <div className='mb-2'>
                                            <Field
                                                name="serie"
                                                placeholder="Serie"
                                                component={renderField}
                                                className="form-control"
                                                msj="Serie"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='mb-2'>
                                            <Field
                                                name="numero"
                                                placeholder="Número"
                                                component={renderField}
                                                className="form-control"
                                                msj="Número"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <Field
                                            name="autorizacion"
                                            disabled={disabled}
                                            placeholder="Numero autorización"
                                            className="form-control"
                                            component={renderField}
                                            msj="Numero autorización"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="clienteN"
                                            disabled={disabled}
                                            placeholder="Cliente"
                                            className="form-control"
                                            component={renderField}
                                            msj="Cliente"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <Field
                                            name="observaciones"
                                            disabled={disabled}
                                            placeholder="Observaciones"
                                            className="form-control"
                                            component={renderField}
                                            msj="Observaciones"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <div>
                                <h5 className="p-2 bg-secondary title mt-1">Detalle factura</h5>
                                <div className='row mb-2'>
                                    <div className='col-3'>
                                        <Field
                                            name="bodega"
                                            placeholder="Bodega..."
                                            className="form-control-find"
                                            loadOptions={listarBodegas}
                                            component={AsyncSelectField}
                                            onChange={handleOnChange}
                                            msj="Bodega"
                                        />
                                    </div>
                                    <div className='col-9'>
                                        <Field
                                            name="producto"
                                            placeholder="Producto..."
                                            className="form-control-find"
                                            loadOptions={pro}
                                            component={AsyncSelectFieldIn}
                                            msj="Producto"
                                        />
                                    </div>
                                    
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-2'>
                                        <Field
                                            name="cantidad"
                                            placeholder="Cantidad"
                                            className="form-control"
                                            component={renderCurrencyFloatTC}
                                            disabled={bloqueo}
                                            msj="Cantidad"
                                        />
                                    </div>
                                    {(datos.agricola === 'S') &&
                                        <div className='col-2'>
                                            <Field
                                                name="unidad"
                                                placeholder="Unidad"
                                                className="form-control"
                                                component={renderNumber}
                                                disabled={bloqueo}
                                                msj="Unidad"
                                            />
                                        </div>
                                    }
                                    <div className='col-2'>
                                        <Field
                                            name="precioVenta"
                                            placeholder="Precio Unitario"
                                            className="form-control"
                                            component={renderCurrencyFloatPrecioVenta}
                                            disabled={bloqueo}
                                            msj="Precio Unitario"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {(crear === true) &&
                            <Contenido>
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => agregarLinea(periodoD)}
                                >
                                    Agregar linea
                                </button>
                            </Contenido>
                        }
                        <br/>
                        {(crear === true) &&
                            <div style={estilo}>
                                <DetalleInventario
                                    detalle={detalleCon}
                                    eliminarLineaProducto={eliminarLineaProducto}
                                />
                            </div>
                        }
                        {(crear === false && editar === false) &&
                            <div style={estilo}>
                                <DetalleInventarioVer
                                    detalle={detalleProducto}
                                />
                            </div>
                        }
                        <br/>
                        {(crear === false && editar === false) &&
                            <div>
                                <div className='row mb-2'>
                                    <div className='col-3'>

                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="base"
                                            disabled={disabled}
                                            placeholder="Base"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Base"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="iva"
                                            disabled={disabled}
                                            placeholder="Iva"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Iva"
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <Field
                                            name="total"
                                            disabled={disabled}
                                            placeholder="Total"
                                            className="form-control"
                                            component={renderCurrencyFloat}
                                            msj="Total"
                                        />
                                    </div>
                                    <div className='col-3'>
                                        
                                    </div>
                                </div>
                            </div>
                        }
                        <br />
                        <div className='d-flex flex-row mt-3'>
                            {crear == true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    onClick={() => registroFacturaBienes(periodoD, detalleCon, iva, tipoD, tipoCambioD, tipoM, tipoFac)}
                                >
                                    Guardar Factura
                                </button>
                            }
                            <a
                                href = {`/#/facturacion/${id_emp[5]}/facturas/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoModal}
                    cambiarEstado={cambiarEstadoModal}
                    titulo="Crear cliente"
                    botonCerrar="true"
                >
                    <div className="d-flex flex-column flex-1 mx-4">

                        <div className='mb-2 w-25'>
                            <Field
                                autoFocus={autoFocus}
                                name="identificador"
                                placeholder="Identificador"
                                className="form-control"
                                component={renderField}
                                msj="Identificador"
                            />
                        </div>
                        <div className='row'>
                            <div className='mb-2 col-8'>
                                <Field
                                    name="nombre"
                                    placeholder="Nombre"
                                    className="form-control"
                                    component={renderField}
                                    msj="Nombre"
                                />
                            </div>
                            {obtenerPaises &&
                                <div className='col-4'>
                                    <div className="form-floating">
                                        <Field
                                            name="pais"
                                            options={obtenerPaises}
                                            crear={crear}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Pais"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='mb-2 w-75'>
                            <Field
                                name="direccion"
                                placeholder="Direccion"
                                className="form-control"
                                component={renderField}
                                msj="Dirección"
                            />
                        </div>
                        <div className='row'>
                            <div className='col-4'>
                                <Field
                                    name="telefono"
                                    placeholder="Telefono"
                                    className="form-control"
                                    component={renderField}
                                    msj="Telefono"
                                />
                            </div>
                            <div className='col-4'>
                                <Field
                                    name="correo"
                                    placeholder="Correo electrónico"
                                    className="form-control"
                                    component={renderField}
                                    msj="Correo electronico"
                                />
                            </div>
                            <div className='col-4'>
                                <Field
                                    name="dias_credito"
                                    placeholder="Dias credito"
                                    className="form-control"
                                    component={renderNumber}
                                    msj="Dias crédito"
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-4'>
                                <Field
                                    name="limite_credito"
                                    placeholder="Limite credito"
                                    className="form-control"
                                    component={renderNumber}
                                    msj="Limite crédito"
                                />
                            </div>
                            <div className='col-4'>
                                <div className="form-floating">
                                    <Field
                                        name="tipo_cliente"
                                        options={tiposCL}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Tipo cliente"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row mt-3'>

                            <button
                                className='btn mr-2 mb-3 btn-primary'
                                onClick={() => registroCliente(datos)}
                            >
                                Guardar
                            </button>

                            <button
                                className='btn mr-2 mb-3 btn-secondary'
                                onClick={() => cambiarEstadoModal(false)}
                            >
                                Salir
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

FacturaBienesForm.propTypes = {
    crear: PropTypes.bool,
    editar: PropTypes.bool,
    bloqueo: PropTypes.bool,
    modulo: PropTypes.object,
    periodoD: PropTypes.object,
    obtenerBodegas: PropTypes.array,
    listarProductos: PropTypes.object,
    detalleProducto: PropTypes.object,
    registroProductoInventario: PropTypes.func,
    eliminarLineaProducto: PropTypes.func,
    confirmarEntrada: PropTypes.func,
    periodoD: PropTypes.object,
    datos: PropTypes.object,
    listarBodegas: PropTypes.func,
    leerProductos: PropTypes.func,
    pro: PropTypes.object,
    monedas: PropTypes.object,
};

const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export default reduxForm({
    form: 'FormFacturaBienesFel',
})(FacturaBienesForm);
