import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const GUARDAR_POLIZA = 'GUARDAR_POLIZA';
const LISTADO_POLIZAS = 'LISTADO_POLIZAS';
const DETALLE_POLIZA = 'DETALLE_POLIZA';
const LOADER = 'LOADER';
const LOADERC = 'LOADERC';
const NIVEL = 'NIVEL';
const MODULO = 'MODULO';
const PARAMETRO = 'PARAMETRO';
const PERIODO_D = 'PERIODOD';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loaderC => ({
    type: LOADERC,
    loaderC,
});

export const listarCuentas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/cuentas/empresa_cuentas/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                if (item.tipo === 'A'){
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre,
                        isDisabled: true
                    })
                }else{
                    list_cuentas.push({
                        value: item.id,
                        label: item.cuenta + ' ' + item.nombre
                    })
                }
            })
        }
        return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

export const listarCC = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/centrocosto/empresa_centro_costo/',params).then(data=>{
        const list_cc = [];
        if(data){
            data.forEach(item=>{
                list_cc.push({
                    value: item.id,
                    label: item.codigo + ' ' + item.descripcion
                })
            })
        }
        return list_cc;
    }).catch((error)=>{
        return [];
    })
}


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let id_period = id_emp[7];
    //const params = { page, id, id_period };
    const params = { id, id_period };
    dispatch(setLoader(true));
    api.get('/polizas', params).then((response)=>{
        dispatch({ type: LISTADO_POLIZAS, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las polizas',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    const params = { id };    
    dispatch(setLoader(true));
    api.get('/polizas/poliza_detalle/', params).then((response) => {
        
        if (response.tipo === 'POLIZA'){
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.polizaDetalle_polizaEncabezado = response.poliza.polizaDetalle_polizaEncabezado
            response.descripcion = response.poliza.descripcion;
            dispatch({type: GUARDAR_POLIZA, lectura: response });
        }
        if (response.poliza.tipo === 'BAN' && response.tipo === 'CKND' && response.tipoD === 'P'){
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.polizaDetalle_polizaEncabezado = response.poliza.polizaDetalle_polizaEncabezado
            response.descripcion = response.poliza.descripcion;
            
            response.tipoDoc = response.banco.tipoDoc;
            response.proveedorN = response.banco.proveedorN;
            response.numero = response.banco.numero_cheque;
            response.tipoD = response.tipoD;
            dispatch({type: GUARDAR_POLIZA, lectura: response });
            
        }
        if (response.poliza.tipo === 'BAN' && response.tipo === 'CKND' && response.tipoD === 'C'){
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.polizaDetalle_polizaEncabezado = response.poliza.polizaDetalle_polizaEncabezado
            response.descripcion = response.poliza.descripcion;
            
            response.tipoDoc = response.banco.tipoDoc;
            response.clienteN = response.banco.clienteN;
            response.numero = response.banco.numero;
            response.tipoD = response.tipoD;
            dispatch({type: GUARDAR_POLIZA, lectura: response });
            
        }
        if (response.poliza.tipo === 'CXP' && response.tipo === 'CMP'){
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.polizaDetalle_polizaEncabezado = response.poliza.polizaDetalle_polizaEncabezado
            response.descripcion = response.poliza.descripcion;
            
            response.proveedorN = response.compra.proveedorN;
            response.serie = response.compra.serie;
            response.numero = response.compra.numero;
            dispatch({type: GUARDAR_POLIZA, lectura: response });
        }
        if (response.poliza.tipo === 'CXP' && response.tipo === 'LIQ'){
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.polizaDetalle_polizaEncabezado = response.poliza.polizaDetalle_polizaEncabezado
            response.descripcion = response.poliza.descripcion;
            
            response.proveedorN = response.liquidacion.proveedorN;
            response.serie = response.liquidacion.serie;
            response.numero = response.liquidacion.numero;
            dispatch({type: GUARDAR_POLIZA, lectura: response });
        }
        if (response.poliza.tipo === 'CXC' && response.tipo === 'AB'){
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.polizaDetalle_polizaEncabezado = response.poliza.polizaDetalle_polizaEncabezado
            response.descripcion = response.poliza.descripcion;
            
            response.clienteN = response.abono.clienteN;
            response.serie = response.abono.serie;
            response.numero = response.abono.numero;
            dispatch({type: GUARDAR_POLIZA, lectura: response });
        }
        const editar = window.location.href.includes('editar');
        if (editar){
            localStorage.setItem('idPoliza', response.poliza.id);
        }
        dispatch(initializeForm('FormPolizas', response ));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la poliza',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPoliza = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizas.values;
    const id = localStorage.getItem('idPoliza');
    const formData = {
        id: id,
        fecha: data.fecha,
        tipo: data.tipo,
        modulo: 'CNT',
        numero_poliza: 0,
        descripcion: data.descripcion,
    }
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00){
        dispatch(setLoader(true));
        api.put(`/polizas/${id}/`, formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            localStorage.removeItem('idPoliza');
            localStorage.removeItem('dif');
            NotificationManager.success(
                'Poliza creada',
                'Exito',
                3000
            );
            let ruta = `/contabilidad/${id_emp[5]}/polizas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Nueva poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(dif)}`,
            type: 'error',
        })
    }
    
}

export const modificarPoliza = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizas.values;
    const id = localStorage.getItem('idPoliza');
    const formData = {
        id: id,
        fecha: data.fecha,
        tipo: data.tipo,
        modulo: 'CNT',
        numero_poliza: 0,
        descripcion: data.descripcion,
    }
    let dif = localStorage.getItem('dif');
    if (parseFloat(dif) === 0.00){
        dispatch(setLoader(true));
        api.put(`/polizas/${id}/`, formData).then((response) => {
            localStorage.removeItem('tipo_poliza');
            localStorage.removeItem('idPoliza');
            localStorage.removeItem('dif');
            NotificationManager.success(
                'Poliza creada',
                'Exito',
                3000
            );
            let ruta = `/contabilidad/${id_emp[5]}/polizas/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                'Ocurrió un error al registrar la poliza',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else{
        Swal.fire({
            title: 'Modificar poliza',
            text: `Poliza con diferencia: ${new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(dif)}`,
            type: 'error',
        })
    }
}

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/polizas/${id}`).then((response) => {
        NotificationManager.success(
            'Poliza borrada correctamente',
            'Exito',
            3000
        );
        let page = 1;
        dispatch(listar(page));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al borrar la poliza',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroPolizaEnc = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizas.values;
    let numero_poliza = 1;
    const poliza = localStorage.getItem('idPoliza');
    if (data === undefined){
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar datos!',
            type: 'error',
        })
    }else{
        const formData = {
            fecha: data.fecha,
            tipo: data.tipo,
            numero_poliza: parseInt(numero_poliza),
            descripcion: data.descripcion,
            modulo: 'CNT',
            empresa: id_emp[5],
            periodo: id_emp[7],
            cc: data.cc.value,
            cuenta: data.cuenta.value,
            debe: data.debe,
            haber: data.haber,
            poliza: poliza
        }
        if (formData.debe === undefined && formData.haber === undefined){
            Swal.fire({
                title: 'Nueva poliza',
                text: 'Debe de ingresar valor en debe o haber',
                type: 'error',
            })
        }else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00){
            Swal.fire({
                title: 'Nueva poliza',
                text: 'No puede llevar valores a 0.00 en debe o haber',
                type: 'error',
            })
        }else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01 ){
            Swal.fire({
                title: 'Nueva poliza',
                text: 'No puede llevar valores en debe y haber',
                type: 'error',
            })
        }else if ( parseFloat(formData.debe) >= 0.01 ) {
            formData.haber = 0.00;
            dispatch(setLoader(true));
            api.post('/polizas/encabezado_polizas/', formData).then((response) => {
                localStorage.removeItem('tipo_poliza');
                const poliza = localStorage.getItem('idPoliza')
                if (poliza === null){
                    response.debe = 0.00;
                    response.haber = 0.00;
                    localStorage.setItem('idPoliza', response.detalle.encabezado);
                    dispatch(initializeForm('FormPolizas', response ));
                    dispatch(leerDetalle(response.detalle.encabezado));
                }else{
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalleMod(poliza));
                    }else{
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalle(poliza));
                    }
                    
                }
                
            }).catch((error) => {
                NotificationManager.error(
                    'Ocurrió un error al registrar la poliza',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else if ( parseFloat(formData.haber) >= 0.01 ) {
            formData.debe = 0.00;
            dispatch(setLoader(true));
            api.post('/polizas/encabezado_polizas/', formData).then((response) => {
                localStorage.removeItem('tipo_poliza');
                const poliza = localStorage.getItem('idPoliza')
                if (poliza === null){
                    response.debe = 0.00;
                    response.haber = 0.00;
                    localStorage.setItem('idPoliza', response.detalle.encabezado);
                    dispatch(initializeForm('FormPolizas', response ));
                    dispatch(leerDetalle(response.detalle.encabezado));
                }else{
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalleMod(poliza));
                    }else{
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalle(poliza));
                    }
                    
                }
                
            }).catch((error) => {
                NotificationManager.error(
                    'Ocurrió un error al registrar la poliza',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
    
}

export const registroPolizaEncSCC = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizas.values;
    let numero_poliza = 1;
    const poliza = localStorage.getItem('idPoliza');
    if (data === undefined){
        Swal.fire({
            title: 'Nueva poliza',
            text: 'Debe de ingresar datos!',
            type: 'error',
        })
    }else{
        const formData = {
            fecha: data.fecha,
            tipo: data.tipo,
            numero_poliza: parseInt(numero_poliza),
            descripcion: data.descripcion,
            modulo: 'CNT',
            empresa: id_emp[5],
            periodo: id_emp[7],
            cc: 0,
            cuenta: data.cuenta.value,
            debe: data.debe,
            haber: data.haber,
            poliza: poliza
        }
        if (formData.debe === undefined && formData.haber === undefined){
            Swal.fire({
                title: 'Nueva poliza',
                text: 'Debe de ingresar valor en debe o haber',
                type: 'error',
            })
        }else if (parseFloat(formData.debe) === 0.00 && parseFloat(formData.haber) === 0.00){
            Swal.fire({
                title: 'Nueva poliza',
                text: 'No puede llevar valores a 0.00 en debe o haber',
                type: 'error',
            })
        }else if (parseFloat(formData.debe) >= 0.01 && parseFloat(formData.haber) >= 0.01 ){
            Swal.fire({
                title: 'Nueva poliza',
                text: 'No puede llevar valores en debe y haber',
                type: 'error',
            })
        }else if ( parseFloat(formData.debe) >= 0.01 ) {
            formData.haber = 0.00;
            dispatch(setLoader(true));
            api.post('/polizas/encabezado_polizas/', formData).then((response) => {
                localStorage.removeItem('tipo_poliza');
                const poliza = localStorage.getItem('idPoliza')
                if (poliza === null){
                    response.debe = 0.00;
                    response.haber = 0.00;
                    localStorage.setItem('idPoliza', response.detalle.encabezado);
                    dispatch(initializeForm('FormPolizas', response ));
                    dispatch(leerDetalle(response.detalle.encabezado));
                }else{
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalleMod(poliza));
                    }else{
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalle(poliza));
                    }
                }
                
            }).catch((error) => {
                NotificationManager.error(
                    'Ocurrió un error al registrar la poliza',
                    'Error',
                    0
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }else if ( parseFloat(formData.haber) >= 0.01 ) {
            formData.debe = 0.00;
            api.post('/polizas/encabezado_polizas/', formData).then((response) => {
                localStorage.removeItem('tipo_poliza');
                const poliza = localStorage.getItem('idPoliza')
                if (poliza === null){
                    response.debe = 0.00;
                    response.haber = 0.00;
                    localStorage.setItem('idPoliza', response.detalle.encabezado);
                    dispatch(leerDetalle(response.detalle.encabezado));
                }else{
                    const editar = window.location.href.includes('editar');
                    if (editar){
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalleMod(poliza));
                    }else{
                        response.debe = 0.00;
                        response.haber = 0.00;
                        dispatch(initializeForm('FormPolizas', response ));
                        dispatch(leerDetalle(poliza));
                    }
                }
                
            }).catch((error) => {
                NotificationManager.error(
                    'Ocurrió un error al registrar la poliza',
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
    
}


const leerDetalle = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        dispatch({ type: DETALLE_POLIZA, detalle: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


const leerDetalleMod = id => (dispatch) => {
    api.get(`/poliza_detalle/${id}`).then((response)=>{
        response.polizaDetalle_polizaEncabezado = response
        dispatch({ type: GUARDAR_POLIZA, lectura: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};


const eliminarLinea = id => (dispatch) => {
    const poliza = localStorage.getItem('idPoliza');
    dispatch(setLoader(true));
    api.eliminar(`poliza_detalle/${id}`).then(() => {
        const editar = window.location.href.includes('editar');
        if (editar){
            dispatch(leerDetalleMod(poliza));
        }else{
            dispatch(leerDetalle(poliza));
        }
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar linea de poliza', 
            'Error', 
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarNiveles = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/niveles/nivel_cuenta', params).then((response)=>{
        dispatch({ type: NIVEL, nivel: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar nivel',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCuenta = (formato) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormPolizas.values;
    let cuenta = data.cuenta
    let f = formato.split('-');
    let cont = 0;
    let formatoCuenta = '';
    let digito = '';
  
    if (f.length === 3){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito;
        
    }
  
    if (f.length === 4){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel4 = f[3].length;
        cont = cont+nivel3;
        digito = cuenta.substr(cont,nivel4);
        formatoCuenta = formatoCuenta + digito;
    }
    
    if (f.length === 5){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel4 = f[3].length;
        cont = cont+nivel3;
        digito = cuenta.substr(cont,nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont+nivel4;
        digito = cuenta.substr(cont,nivel5);
        formatoCuenta = formatoCuenta + digito;
    }

    if (f.length === 6){
        let nivel1 = f[0].length
        digito = cuenta.substr(cont,nivel1);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel2 = f[1].length;
        cont = cont+nivel1; 
        digito = cuenta.substr(cont,nivel2);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel3 = f[2].length;
        cont = cont+nivel2; 
        digito = cuenta.substr(cont,nivel3);
        formatoCuenta = formatoCuenta + digito + '-';
        
        let nivel4 = f[3].length;
        cont = cont+nivel3;
        digito = cuenta.substr(cont,nivel4);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel5 = f[4].length;
        cont = cont+nivel4;
        digito = cuenta.substr(cont,nivel5);
        formatoCuenta = formatoCuenta + digito + '-';

        let nivel6 = f[5].length;
        cont = cont+nivel5;
        digito = cuenta.substr(cont,nivel6);
        formatoCuenta = formatoCuenta + digito;
    }
    let idPoliza = localStorage.getItem('idPoliza')
    const formData = {
        cuenta: formatoCuenta,
        nombre: data.nombre,
        tipo: data.tipoC,
        naturaleza: data.naturaleza,
        empresa: id_emp[5],
        poliza: idPoliza
    }
    dispatch(setLoaderC(true));
    api.post('/cuentas/', formData).then((response) => {
        if (idPoliza){
            NotificationManager.success(
                'Cuenta creada'+' '+response.cuenta.cuenta+' '+response.cuenta.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            response.fecha = response.poliza.fecha;
            response.tipo =  response.poliza.tipo;
            response.numero_poliza = response.poliza.numero_poliza;
            response.descripcion = response.poliza.descripcion;
            listarCuentas();
            
        }else{
            NotificationManager.success(
                'Cuenta creada'+' '+response.cuenta+' '+response.nombre,
                'Exito',
                0
            );
            response.cuenta = '';
            response.nombre = '';
            listarCuentas();    
        }

    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const reporteCuentas = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let reporte = 'reporte';
    const params = { id, reporte };
    dispatch(setLoaderC(true));;
    api.getPdf('/cuentas', params).then((response)=>{
        if (response.headers['content-type'] == 'application/pdf'){
            var blob = new Blob([response.text], { type: 'application/pdf;charset=utf-8' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'reporte_cuentas_contables.pdf';
            window.open(link, '_blank');
            return false;
          }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al generar reporte',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const actions = {
    registroPoliza,
    modificarPoliza,
    leer,
    listarCuentas,
    listar,
    listarCC,
    eliminar,
    registroPolizaEnc,
    registroPolizaEncSCC,
    leerDetalle,
    eliminarLinea,
    listarNiveles,
    registroCuenta,
    reporteCuentas,
    getModulo,
    parametrosEmpresa,
    leerPeriodoD
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [GUARDAR_POLIZA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [LISTADO_POLIZAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DETALLE_POLIZA]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [NIVEL]: (state, { nivel }) => {
        return {
            ...state,
            nivel,
        };
    },
    [LOADERC]: (state, { loaderC }) => {
        return {
            ...state,
            loaderC,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
};

export const initialState = {
    loader: false,
    loaderC: false,
    cuentas: null,
    lectura: {
        polizaDetalle_polizaEncabezado: []
    },
    detalle: [],
    nivel: [],
    modulo: [],
    datos: [],
    periodoD: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)